import React from 'react';
import {injectIntl} from 'react-intl';

import NestedDropdown from '../../forecast-app/shared/components/dropdowns/nested_dropdown';
import Util from '../../forecast-app/shared/util/util';
import {PROJECT_STATUS} from '../../constants';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

const projectDropdown = ({intl, viewer, project, onChange, cy}) => {
	const getProjectOptions = () => {
		let projectOptions = [];
		const hasOpportunityAccess = Util.hasOpportunityAccess(viewer.company.modules);

		const running_projects = [],
			planning_projects = [],
			opportunity_projects = [];

		viewer.projects?.edges
			.filter(project => project.node.status !== PROJECT_STATUS.DONE)
			.forEach(project => {
				const el = {
					id: project.node.id,
					projectColor: project.node.projectColor,
					name: project.node.name,
					label: project.node.name,
					companyProjectId: project.node.companyProjectId,
					customProjectId: project.node.customProjectId,
					sprintTimeBox: project.node.sprintTimeBox,
					sortValue: project.node.companyProjectId,
					isHalted: project.node.status === PROJECT_STATUS.HALTED,
					isDone: project.node.status === PROJECT_STATUS.DONE,
				};
				if (
					project.node.status === PROJECT_STATUS.PLANNING ||
					(project.node.status === PROJECT_STATUS.OPPORTUNITY && !hasOpportunityAccess)
				) {
					planning_projects.push(el);
				} else if (project.node.status === PROJECT_STATUS.RUNNING) {
					running_projects.push(el);
				} else if (project.node.status === PROJECT_STATUS.OPPORTUNITY && hasOpportunityAccess) {
					opportunity_projects.push(el);
				}
			});
		viewer.projectGroups.edges
			.filter(
				projectGroup =>
					projectGroup.node.projects &&
					projectGroup.node.projects.edges.filter(project => project.node.status !== PROJECT_STATUS.DONE).length !== 0
			)
			.forEach(projectGroup => {
				const groupEl = {
					color: projectGroup.node.color,
					name: projectGroup.node.name,
					label: projectGroup.node.name,
					companyProjectGroupId: projectGroup.node.companyProjectGroupId,
					isProjectGroup: true,
					sortValue: projectGroup.node.companyProjectGroupId + '_group',
				};
				const addSubProjects = projects => {
					projectGroup.node.projects.edges.forEach(project => {
						if (project.node.status !== PROJECT_STATUS.HALTED && project.node.status !== PROJECT_STATUS.DONE) {
							projects.push({
								id: project.node.id,
								projectColor: project.node.projectColor,
								name: project.node.name,
								label: project.node.name,
								companyProjectId: project.node.companyProjectId,
								customProjectId: project.node.customProjectId,
								projectGroupId: project.node.projectGroupId,
								sprintTimeBox: project.node.sprintTimeBox,
								isInProjectGroup: true,
								sortValue: projectGroup.node.companyProjectGroupId + '_group',
								isHalted: project.node.status === PROJECT_STATUS.HALTED,
								isDone: project.node.status === PROJECT_STATUS.DONE,
							});
						}
					});
				};
				if (
					projectGroup.node.projects &&
					projectGroup.node.projects.edges.filter(project => project.node.status === PROJECT_STATUS.RUNNING)
						.length !== 0
				) {
					running_projects.push(groupEl);
					addSubProjects(running_projects);
				} else if (
					projectGroup.node.projects &&
					projectGroup.node.projects.edges.filter(project => project.node.status === PROJECT_STATUS.PLANNING)
						.length !== 0
				) {
					planning_projects.push(groupEl);
					addSubProjects(planning_projects);
				} else if (
					projectGroup.node.projects &&
					projectGroup.node.projects.edges.filter(project => project.node.status === PROJECT_STATUS.OPPORTUNITY)
						.length !== 0
				) {
					opportunity_projects.push(groupEl);
					addSubProjects(opportunity_projects);
				}
			});
		const sortProjects = (a, b) => {
			if (a.sortValue < b.sortValue) return -1;
			if (a.sortValue > b.sortValue) return 1;
			if (a.sortValue === b.sortValue) {
				if (a.isInProjectGroup && !b.isInProjectGroup) {
					return 1;
				}
			}
			return 0;
		};
		running_projects.sort(sortProjects);
		planning_projects.sort(sortProjects);
		opportunity_projects.sort(sortProjects);
		projectOptions.push(
			{
				value: 'running_projects',
				label: intl.formatMessage({id: 'project_status.running'}),
				nestedOptions: running_projects,
			},
			{
				value: 'planning_projects',
				label: intl.formatMessage({id: 'project_status.planning'}),
				nestedOptions: planning_projects,
			},
			{
				value: 'opportunity_projects',
				label: intl.formatMessage({id: 'project_status.opportunity'}),
				nestedOptions: opportunity_projects,
			}
		);

		return projectOptions;
	};

	return (
		<NestedDropdown
			placeholder={intl.formatMessage({id: 'task_modal.move_to_new_project'})}
			label={project.client ? project.client.name : intl.formatMessage({id: 'common.project'})}
			options={getProjectOptions()}
			value={project.name}
			projectIdLabel={{
				companyProjectId: project.companyProjectId,
				customProjectId: project.customProjectId,
				name: project.name,
				projectColor: project.projectColor,
				isGroupProject: project.isProjectGroup,
			}}
			onChange={onChange}
			customClass="project-dropdown"
			showArrowWhenCollapsed={true}
			disablePreselectedOptionRemove
			cy={cy}
			userpilot={'project-dropdown'}
			nativeScrollbar={hasFeatureFlag('native_scrollbar_replacement')}
		/>
	);
};

const projectDropdownQuery = graphql`
	query projectDropdown_Query($id: String) {
		viewer {
			actualPersonId
			component(name: "project_dropdown")
			...projectDropdown_viewer @arguments(id: $id)
		}
	}
`;

export {projectDropdownQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(projectDropdown, {
			viewer: graphql`
				fragment projectDropdown_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
					company {
						id
						modules {
							moduleType
						}
					}
					projects(first: 10000, excludeGroupProjects: true, excludeRestricted: true) {
						edges {
							node {
								id
								status
								companyProjectId
								customProjectId
								name
								projectColor
								sprintTimeBox
							}
						}
					}
					projectGroups(first: 10000) @connection(key: "Viewer_projectGroups", filters: []) {
						edges {
							node {
								id
								companyProjectGroupId
								name
								color
								projects(first: 10000) {
									edges {
										node {
											id
											projectColor
											companyProjectId
											customProjectId
											projectGroupId
											name
											status
										}
									}
								}
							}
						}
					}
				}
			`,
		})
	)
);
