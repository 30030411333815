import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Input from '../../../../components/new-ui/input_field';
import DatePicker from '../../../../forecast-app/shared/components/date-picker/date_picker_v3';
import UpdateTaskMutationModern from '../../../../mutations/update_task_mutation.modern';
import AssignPersonToTaskMutation from '../../../../mutations/assign_person_to_task_mutation';
import UnassignPersonFromTaskMutation from '../../../../mutations/unassign_person_from_task_mutation';
import Util from '../../../../forecast-app/shared/util/util';
import AssignedDropdown from '../../../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import ForecastTile from './forecast_tile';
import TimeEntriesTileRenderer from './time_entries_tile_renderer';
import RemainingTile from './remaining_tile';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, DeadlineFrom, HIDDEN_FEATURES} from '../../../../constants';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import UpdateSuggestedTaskLabelsMutation from '../../../../mutations/update_suggested_task_labels_mutation';
import {TaskRollupSectionRenderer} from './TaskRollupSection/TaskRollupSectionRenderer';
import {ProgressTile} from './progress-tile/ProgressTile';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {isDateDisabled} from '../../../../forecast-app/shared/util/DateUtil';
import {TaskModalTimeEntriesPlaceholder} from '../../../../forecast-app/shared/components/placeholders/TaskModalTimeEntriesPlaceholder';

class MainSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			taskName: props.task.name,
			taskNameFocus: false,
			isClientViewRestricted: Util.isClientTaskViewRestricted(this.props.viewer),
			isClientActionsRestricted: Util.isClientTaskActionsRestricted(this.props.viewer),
		};
		this.onKeyDown = this.onKeyDown.bind(this);
		//Add listener on body so that it is executed before listeners on document/window
		document.body.addEventListener('keydown', this.onKeyDown);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.task.name !== this.state.taskName && !this.state.taskNameFocus) {
			this.setState({taskName: nextProps.task.name});
		}
	}

	componentWillUnmount() {
		document.body.removeEventListener('keydown', this.onKeyDown);
	}

	onTaskNameChange(taskName) {
		this.setState({taskName, taskNameFocus: true});
	}

	onDateUpdateSuccess(result) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		} else {
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
		}
	}

	onTaskNameUpdateSuccess(result) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		} else {
			dispatch(EVENT_ID.SHOW_CHANGES_SAVED);
			Util.CommitMutation(UpdateSuggestedTaskLabelsMutation, {
				taskId: this.props.task.id,
				name: name,
			});
		}
	}

	onTaskNameBlur() {
		this.setState({taskNameFocus: false});
		if (this.state.taskName && this.state.taskName === this.props.task.name) {
			return;
		}

		const name = this.state.taskName.trim().slice(0, 189) || this.props.intl.formatMessage({id: 'common.new-task'});

		this.setState({taskName: name});
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				name,
			},
			this.onTaskNameUpdateSuccess.bind(this)
		);
	}

	onKeyDown(e) {
		if (e.key.toLowerCase() === 'escape') {
			if (document.activeElement && document.activeElement.id === 'task-modal-task-name') {
				this.onTaskNameBlur();
			}
		}
	}

	onTaskDateChange(startDate, endDate) {
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				deadlineYear: endDate.year(),
				deadlineMonth: endDate.month() + 1,
				deadlineDay: endDate.date(),
			},
			this.onDateUpdateSuccess.bind(this)
		);
	}
	clearBothDates() {
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				startYear: null,
				startMonth: null,
				startDay: null,
				deadlineYear: null,
				deadlineMonth: null,
				deadlineDay: null,
			},
			this.onDateUpdateSuccess.bind(this)
		);
	}

	showUnassignPrompt(deleteNode, personId) {
		const {formatMessage} = this.props.intl;
		const callbackPositive = params => {
			this.unassignPerson(deleteNode, personId);
			this.props.closeModal();
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId={'task_modal.follower_client_warning'} />
				</div>
			),
			className: 'default-warning-modal',
			cy: 'task-modal-follower-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.confirm'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
				},
			],
		});
	}

	assignPerson(personId) {
		if (personId) {
			Util.CommitSchedulingModalUpdate(
				AssignPersonToTaskMutation,
				{
					taskId: this.props.task.id,
					personId: personId,
				},
				() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
			);
		}
	}

	unassignPerson(deleteNode, personId) {
		Util.CommitSchedulingModalUpdate(
			UnassignPersonFromTaskMutation,
			{
				taskId: this.props.task.id,
				personId: personId,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	}

	assignRole(roleId) {
		Util.CommitSchedulingModalUpdate(
			UpdateTaskMutationModern,
			{
				ids: [this.props.task.id],
				roleId,
			},
			() => dispatch(EVENT_ID.SHOW_CHANGES_SAVED)
		);
	}

	getStartFrom() {
		const {formatMessage} = this.props.intl;
		const {task} = this.props;
		let startFrom = '';
		switch (task.startFrom) {
			case DeadlineFrom.PROJECT:
				startFrom = task.project && task.project.name ? task.project.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.PHASE:
				startFrom = task.phase && task.phase.name ? task.phase.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.SPRINT:
				startFrom = task.sprint && task.sprint.name ? task.sprint.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.TASK:
				startFrom = 'parent task';
				break;
			default:
				break;
		}
		return startFrom;
	}

	getEndFrom() {
		const {formatMessage} = this.props.intl;
		const {task} = this.props;
		let endFrom = '';
		switch (task.deadlineFrom) {
			case DeadlineFrom.PROJECT:
				endFrom = task.project && task.project.name ? task.project.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.PHASE:
				endFrom = task.phase && task.phase.name ? task.phase.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.SPRINT:
				endFrom = task.sprint && task.sprint.name ? task.sprint.name : formatMessage({id: 'common.untitled'});
				break;
			case DeadlineFrom.TASK:
				endFrom = 'parent task';
				break;
			default:
				break;
		}
		return endFrom;
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {
			viewer,
			task,
			project,
			project: {isJiraProject},
			isTaskReadOnly,
			isClientUser,
		} = this.props;
		const {taskName, isClientViewRestricted, isClientActionsRestricted} = this.state;
		const {startYear, startMonth, startDay, deadlineYear, deadlineMonth, deadlineDay, owner, followers} = task;
		const {useTaskOwner, useTaskFollowers} = project;
		const startDate = Util.CreateNonUtcMomentDate(startYear, startMonth, startDay);
		const endDate = Util.CreateNonUtcMomentDate(deadlineYear, deadlineMonth, deadlineDay);
		const startFrom = this.getStartFrom();
		const endFrom = this.getEndFrom();
		const startDateWarnings = Util.GetWarningsForTaskStartDate(task, this.props.intl);
		const deadlineDateWarnings = Util.GetWarningsForTaskDeadline(task, task.project, this.props.intl);
		const isOwnerAssigned = owner ? task.assignedPersons.some(person => person.id === owner.id) : false;
		const isVieweFollowerAndAssigned = followers
			? task.assignedPersons.some(person =>
					followers.some(follower => follower.id === person.id && follower.id === viewer.actualPersonId)
			  )
			: false;

		const usingManualProgress = ProjectUtil.projectHasManualProgressSettingEnabled(project);

		let assignedPersons = task.assignedPersons;
		if (isClientViewRestricted) {
			assignedPersons = assignedPersons.filter(person => person.id === viewer.actualPersonId);
		}

		return (
			<div
				className={'task-modal-v3-main-section' + (isTaskReadOnly ? ' project-locked' : '')}
				style={{backgroundColor: project.projectColor}}
				data-cy="task-modal-main-section"
			>
				<div className="top-row">
					<Input
						ref={e => (this.nameInput = e)}
						id="task-modal-task-name"
						type="text"
						placeholder={formatMessage({id: 'common.task_name'})}
						value={taskName}
						onChange={this.onTaskNameChange.bind(this)}
						onBlur={this.onTaskNameBlur.bind(this)}
						hideLabel={true}
						autoFocus={true}
						title={taskName}
						cy="task-name-input"
						taskId={this.props.task?.name?.length > 0 ? `T${task.companyTaskId}` : null}
						locked={isTaskReadOnly || this.props.task.jiraKey}
						userpilot={'task-modal-title'}
					/>

					<AssignedDropdown
						assignablePersons={project.projectPersons?.edges || []}
						assignedPersons={assignedPersons}
						assignPersonOnlyDiff={true}
						assignPerson={this.assignPerson.bind(this)}
						unassignPerson={
							(useTaskOwner && isClientUser && !isOwnerAssigned) ||
							(useTaskFollowers && isClientUser && !isVieweFollowerAndAssigned)
								? this.showUnassignPrompt.bind(
										this,
										(!isOwnerAssigned || !isVieweFollowerAndAssigned) && isClientUser
								  )
								: this.unassignPerson.bind(
										this,
										(useTaskOwner ? !isOwnerAssigned : !isVieweFollowerAndAssigned) && isClientUser
								  )
						}
						closeOnUnassign={isClientUser}
						isMultiSelect={!isJiraProject}
						label={formatMessage({id: 'common.assignees'})}
						maxPeopleIconsShown={6}
						cy="task-modal-assignee-dropdown"
						isClientUser={isClientUser}
						viewerId={viewer.actualPersonId}
						disabled={isTaskReadOnly || isClientViewRestricted || isClientActionsRestricted}
						viewer={viewer}
						task={task}
						useSmallerStyling={true}
						suggestedPersonRoleFilter={task.role}
						showSuggestions={true}
						userpilot={'task-modal-assignee-dropdown'}
					/>
				</div>
				<div className="bottom-row">
					{this.props.hasChildren ? (
						<TaskRollupSectionRenderer
							task={task}
							project={project}
							disabled={isTaskReadOnly || isClientActionsRestricted}
							isClientUser={isClientUser}
							intl={this.props.intl}
							personId={viewer.actualPersonId}
							showTimeEntries={this.props.showTimeEntries}
						></TaskRollupSectionRenderer>
					) : (
						<div className="hour-input-section">
							<ForecastTile
								task={task}
								project={project}
								disabled={isTaskReadOnly || isClientActionsRestricted}
								cy="task-modal-forecast-button"
							/>
							{usingManualProgress ? (
								<ProgressTile task={task} usingManualProgress={usingManualProgress} intl={this.props.intl} />
							) : (
								<>
									{this.props.showTimeEntries ? (
										<TimeEntriesTileRenderer
											taskId={task.id}
											isTwoLevelSubTask={this.props.project.taskLevels === 2}
											disabled={
												isTaskReadOnly ||
												isClientActionsRestricted ||
												Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
											}
											cy="task-modal-entries-button"
											isClientUser={isClientUser}
											personId={viewer.actualPersonId}
										/>
									) : (
										<TaskModalTimeEntriesPlaceholder />
									)}
								</>
							)}
							<RemainingTile
								task={task}
								project={project}
								disabled={isTaskReadOnly || isClientActionsRestricted}
								cy="task-modal-remaining-time-button"
							/>
						</div>
					)}

					<DatePicker
						isNewDateRange={true}
						startDate={startDate}
						startDateLabel={formatMessage({id: 'common.start'})}
						endDate={endDate}
						endDateLabel={formatMessage({id: 'common.deadline'})}
						handleDateRangeChange={this.onTaskDateChange.bind(this)}
						clearable={true}
						clearBothDates={this.clearBothDates.bind(this)}
						startFrom={startFrom}
						endFrom={endFrom}
						datesInheritedFrom={task.startFrom ? task.startFrom : null}
						datePickerStyle={DATE_PICKER_STYLE.TASK_MODAL_TASK_DATE}
						disabled={isTaskReadOnly || isClientActionsRestricted}
						startDateWarnings={startDateWarnings.showWarning ? startDateWarnings : null}
						deadlineDateWarnings={deadlineDateWarnings.showWarning ? deadlineDateWarnings : null}
						buttonCy={'date-picker-button'}
						userpilot={'task-modal-date-picker'}
						disabledDates={isDateDisabled}
					/>

					<AssignedDropdown
						assignableRoles={this.props.availableRoles}
						assignedRole={isClientViewRestricted ? null : task.role}
						assignRole={this.assignRole.bind(this)}
						isMultiSelect={!isJiraProject}
						label={formatMessage({id: 'common.role'})}
						maxPeopleIconsShown={6}
						cy="task-modal-role-dropdown"
						isClientUser={isClientUser}
						viewerId={viewer.actualPersonId}
						disabled={isTaskReadOnly || isClientViewRestricted || isClientActionsRestricted}
						viewer={viewer}
						task={task}
						useSmallerStyling={true}
						showSuggestions={true}
						userpilot={'task-modal-role-dropdown'}
					/>
				</div>
			</div>
		);
	}
}

MainSection.propTypes = {
	viewer: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	availableRoles: PropTypes.array.isRequired,
};

export default injectIntl(MainSection);
