import {injectIntl} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import Util from '../../../forecast-app/shared/util/util';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {DATE_PICKER_STYLE, ESTIMATION_UNIT, PROJECT_STATUS} from '../../../constants';
import NumericTile from '../numeric_tile';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import AssignedTableTile from '../../../forecast-app/shared/components/table-tiles/assigned_table_tile';
import TextTile from '../text_tile';
import UpdateTaskMutation from '../../../mutations/update_task_mutation.modern';
import AssignPersonToTaskMutation from '../../../mutations/assign_person_to_task_mutation';
import UnassignPersonFromTaskMutation from '../../../mutations/unassign_person_from_task_mutation';
import CheckBox from '../check_box';
import WarningIcon from '../../../images/warning_icon';
import TaskTableInputField from './table-row-elements/task_table_input_field';
import * as tracking from '../../../tracking';
import StarTask from '../../../forecast-app/shared/components/star/star_task';
import Moment from 'moment';
import TimeRegPopup from '../../../forecast-app/shared/components/popups/time_reg_popup';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import NumericTiles from './numeric-tiles/numeric_tiles_view';
import LabelGroup from '../../../forecast-app/shared/components/labels/label_group';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {PredictionPopupInputExtension} from '../../../forecast-app/shared/components/task-tables/task-table-v3/elements/task-row-elements/prediction-popup/PredictionPopupInputExtension';
import {showOverrunPredictionWarning} from '../../../forecast-app/shared/util/PredictionUtil';
import {VARIANT} from '../../../containers/modal/project-automate/project_automate_modal';
import {TaskParentName} from './table_row.styled';
import {
	NumericTileV2,
	UNIT,
} from '../../../forecast-app/shared/components/task-tables/task-table-v3/elements/task-row-elements/NumericTile';
import {TaskProgressRegistrationPopup} from '../../../forecast-app/shared/components/popups/progress-registration/TaskProgressRegistrationPopup';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {isDateDisabled} from '../../../forecast-app/shared/util/DateUtil';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import {projectUrl} from '../../../directApi';
import {getTotalFromTimeRegs} from './time_reg_util';

class TableRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newTaskInput: '',
			remainingInput: props.task ? props.task.timeLeft : null,
		};
		this.getElemForCol = this.getElemForCol.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Don't update if our estimate fields currently have focus

		if (this.getEstimateInputsFocused() || nextProps.task) {
			this.setState({
				remainingInput: nextProps.task.timeLeft,
			});
		}
	}

	shouldComponentUpdate(nextProps) {
		return !(this.props.dragActive && nextProps.dragActive);
	}

	getEstimateInputsFocused() {
		const activeElem = document.activeElement;
		if (activeElem) {
			return activeElem.id.includes('estimate');
		}
	}

	handleSelected(e) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		if (this.props.onRowSelected) {
			this.props.onRowSelected(this.props.id, e.shiftKey, e.ctrlKey, this.props.emptyPhase);
		}
		if (this.state.showContextMenu) {
			this.setState({showContextMenu: false});
		}
	}

	refreshRemaining() {
		this.setState({remainingInput: this.props.task.timeLeft});
	}

	updateTaskRemaining() {
		let task = this.props.task;
		const taskProject = this.props.isConnectedParent
			? this.props.viewer.projectGroup.projects.edges.find(project => project.node.id === task.project.id).node
			: this.props.viewer.project
			? this.props.viewer.project
			: this.props.task.project;

		if (this.props.disabled || this.props.projectLocked) {
			return;
		}

		let remainingInput = this.state.remainingInput;
		const shouldUpdateRemaining = task.timeLeft !== remainingInput;
		if (shouldUpdateRemaining && !taskProject.remainingAutoCalculated) {
			const mutationObject = {
				ids: [task.id],
				remaining: remainingInput,
			};
			Util.CommitMutation(UpdateTaskMutation, mutationObject);
		}

		if (!shouldUpdateRemaining) {
			this.refreshRemaining();
		}
	}

	onDateUpdateSuccess(result) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		}
	}

	clearDates() {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [this.props.task.id],
				startYear: null,
				startMonth: null,
				startDay: null,
				deadlineYear: null,
				deadlineMonth: null,
				deadlineDay: null,
			},
			this.onDateUpdateSuccess
		);
	}

	handleDateRangeChange(startDate, endDate) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		Util.CommitMutation(
			UpdateTaskMutation,
			{
				ids: [this.props.task.id],
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				deadlineYear: endDate.year(),
				deadlineMonth: endDate.month() + 1,
				deadlineDay: endDate.date(),
			},
			this.onDateUpdateSuccess
		);
	}

	handleInputChange(key, val) {
		const task = this.props.task;
		if (
			this.props.disabled ||
			this.props.projectLocked ||
			task.project.status === PROJECT_STATUS.DONE ||
			task.project.status === PROJECT_STATUS.HALTED
		) {
			return;
		}
		const inputVal = this.props.isEstimatedInHours
			? Util.getMinuteEstimate(Number(val))
			: this.props.task.project.estimationUnit === 'HOURS'
			? Util.getMinuteEstimate(Number(val))
			: Number(val);
		this.setState({[key]: inputVal});
	}

	onEstimateUpdateSuccess(result) {
		if (result.updateTask.errors && result.updateTask.errors.length === 1) {
			Util.checkForSageErrorAndShowModal(result.updateTask.errors);
		}
	}

	handleEstimationChanges(key, val) {
		if (isNaN(val)) return;
		const valRounded = Math.round(val); // Round to avoid edge cases caused by JS floating point representation

		const task = this.props.task;

		if (task.estimateForecast === valRounded) return;
		const latestOverrunPredictionTask = localStorage.getItem('latest-overrun-prediction-shown');
		if (latestOverrunPredictionTask === task.id) {
			tracking.trackAIEvent('Recently seen overrun reestimated', VARIANT.ESTIMATES, null, {
				appliedEstimate: valRounded,
				predictedEstimate: task.predictedEstimate?.estimate,
				taskId: task.id,
				taskName: task.name,
			});
			trackEvent('Recently Inspected Predicted Overrun Task', 'Reestimated', {
				appliedEstimate: valRounded,
				predictedEstimate: task.predictedEstimate?.estimate,
				taskId: task.id,
				taskName: task.name,
			});
			localStorage.removeItem('latest-overrun-prediction-shown');
		}
		const mutation = {
			ids: [task.id],
			forecast: valRounded,
		};
		Util.CommitMutation(UpdateTaskMutation, mutation, this.onEstimateUpdateSuccess);

		this.setState({[key]: val});
	}

	handleRemainingChange(val) {
		Util.CommitMutation(UpdateTaskMutation, {ids: [this.props.task.id], remaining: val});
	}

	onTaskApprovalChange(task, option) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [task.id],
			approved: option.value,
		});
	}

	toggleContextMenu(e) {
		if (!this.props.lazyDataFetched || this.props.disabled || this.props.projectLocked) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();
		this.props.onContextMenu(this.props.task, e);
	}

	onRowBlur() {
		this.setState({showContextMenu: false});
	}

	assignPersonsToTask(personId) {
		const task = this.props.task;
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		if (task && personId) {
			Util.CommitMutation(AssignPersonToTaskMutation, {
				taskId: task.id,
				personId: personId,
			});
		}
	}

	assignRoleToTask(role) {
		let task = this.props.task;
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [task.id],
			roleId: role,
		});
	}

	setOwner(deleteNode, personId) {
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [this.props.task.id],
			ownerId: Array.isArray(personId) ? personId[0] : personId,
			deleteNode,
		});
	}

	unassignPersonFromTask(personId) {
		let task = this.props.task;
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		if (task && personId) {
			Util.CommitMutation(UnassignPersonFromTaskMutation, {
				taskId: task.id,
				personId: personId,
			});
		}
	}

	validateRemaining() {
		return Number(this.state.remainingInput) >= 0;
	}

	onStatusColumnChange(option) {
		const {task, intl} = this.props;
		Util.changeTaskStatus(task, option, intl);
	}

	onSprintChange(selected) {
		const onSuccess = response => {
			if (
				response &&
				response.updateTask &&
				response.updateTask.errors &&
				response.updateTask.errors.includes('jira_sprint_change_failed')
			) {
				// Jira rejected the sprint change. Show message to user.
				Util.showJiraSprintChangeRejected(this.props.intl);
			}
		};

		const mutationObject = {
			ids: [this.props.task.id],
		};

		if (this.props.viewer.project) {
			mutationObject.sprintId = selected ? selected.value : null;
		} else {
			mutationObject.projectGroupSprintId = selected ? selected.value : null;
		}
		mutationObject.projectId = this.props.task.project.id;
		mutationObject.pageContext = this.props.parentType;
		mutationObject.prevStates = [{node: this.props.task}];
		Util.CommitMutation(UpdateTaskMutation, mutationObject, onSuccess);
	}

	onPhaseChange(selected) {
		const phaseId = selected ? selected.value : null;
		Util.CommitMutation(UpdateTaskMutation, {
			ids: [this.props.task.id],
			phaseId: phaseId,
		});
	}

	onTaskClick(e, task, elem) {
		if (elem !== 'div' && !this.props.selectionMode && !e.shiftKey) {
			const newTarget = e.relatedTarget || e.explicitOriginalTarget;
			if (newTarget && (newTarget.tagName === 'INPUT' || newTarget.tagName === 'Button')) {
				return;
			}
			tracking.trackElementClicked('Task name');
			trackEvent('Task Name', 'Clicked');
			this.openCardModal(e, task);
		}
	}

	openCardModal(e, item) {
		e.stopPropagation();
		e.preventDefault();
		if (this.props.showTaskModal) {
			this.props.showTaskModal(item.companyTaskId);
		}
	}

	getNumberOfIconsForTask(task) {
		const hasWarning = Util.GetTaskWarnings(task) != null;
		// Boolean to number conversion, woooh!
		return task.bug + task.blocked + task.highPriority + hasWarning;
	}

	getProjectNavigationString(project) {
		const curLocArr = window.location.href.split('/');
		const pageName = curLocArr[curLocArr.length - 1];
		return projectUrl(project.companyProjectId, project.customProjectId) + '/' + pageName;
	}

	getDeadlineTextForTask(task) {
		const {formatMessage} = this.props.intl;
		if (task.statusColumnV2 && task.statusColumnV2.category === 'DONE') {
			return <span className={'done date-text'}>{formatMessage({id: 'common.done'})}</span>;
		}
		const today = Moment();
		if (task.deadlineDay == null) {
			return '';
		}
		const taskDeadline = Util.CreateMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);
		if (today.isSame(taskDeadline, 'date')) {
			return <span className={'today date-text'}>{formatMessage({id: 'common.today'})}</span>;
		} else if (today.add(1, 'days').isSame(taskDeadline, 'date')) {
			return <span className={'today date-text'}>{formatMessage({id: 'common.tomorrow'})}</span>;
		} else if (today.isAfter(taskDeadline, 'date')) {
			return <span className={'overdue date-text'}>{taskDeadline.format('DD MMM')}</span>;
		}
		return <span className={'date-text'}>{taskDeadline.format('DD MMM')}</span>;
	}

	getElemForCol(col, taskId, selector) {
		const {formatMessage, formatNumber} = this.props.intl;
		const task = this.props.task;
		const taskProject = this.props.isConnectedParent
			? this.props.viewer.projectGroup.projects.edges.find(project => project.node.id === task.project.id).node
			: this.props.viewer.project
			? this.props.viewer.project
			: task.project;
		const taskCellMaxWidth = this.props.taskCellMaxWidth;
		const colName = col.name;
		const groupByPerson = this.props.groupByPerson || this.props.groupByRole;

		const totalTime = task.timeRegistrations
			? getTotalFromTimeRegs(
					task.timeRegistrations.edges,
					false,
					timeReg => timeReg.node.minutesRegistered,
					this.props.groupByRole
			  ) / 60
			: 0;
		const totalBillableTime = task.timeRegistrations
			? getTotalFromTimeRegs(
					task.timeRegistrations.edges,
					false,
					timeReg => timeReg.node.billableMinutesRegistered,
					this.props.groupByRole
			  ) / 60
			: 0;
		const totalNonBillableTime = totalTime - totalBillableTime;

		const personTimeRegs =
			groupByPerson && task.timeRegistrations
				? getTotalFromTimeRegs(
						task.timeRegistrations.edges,
						this.props.groupPersonId,
						timeReg => timeReg.node.minutesRegistered,
						this.props.groupByRole
				  ) / 60
				: 0;

		const personBillableTimeRegs =
			groupByPerson && task.timeRegistrations
				? getTotalFromTimeRegs(
						task.timeRegistrations.edges,
						this.props.groupPersonId,
						timeReg => timeReg.node.billableMinutesRegistered,
						this.props.groupByRole
				  ) / 60
				: 0;
		const personNonBillableTimeRegs = personTimeRegs - personBillableTimeRegs;

		const forecast =
			task && task.project
				? task.project.estimationUnit === ESTIMATION_UNIT.HOURS
					? task.estimateForecast / 60
					: task.estimateForecast
				: this.props.isEstimatedInHours
				? task.estimateForecast / 60
				: task.estimateForecast;
		const disableActions =
			!this.props.lazyDataFetched || this.props.projectLocked || this.props.disabled || this.props.selectionMode;
		const numberOfPerson = this.props.numPersonsAssignedToTask != null ? this.props.numPersonsAssignedToTask : 1;
		const isShared = numberOfPerson > 1;

		const taskAssignedNames =
			task.assignedPersons.length > 2 // Oxford comma rule of three
				? task.assignedPersons
						.map(person => person.firstName + ' ' + person.lastName)
						.reduce(
							(acc, name, index) =>
								index === task.assignedPersons.length - 1
									? acc.concat(' and ' + name)
									: acc.concat(name + ', '),
							''
						)
				: task.assignedPersons
						.map(person => person.firstName + ' ' + person.lastName)
						.reduce(
							(acc, name, index) =>
								index === task.assignedPersons.length - 1 ? acc.concat(' and ' + name) : acc.concat(name + ' '),
							''
						);

		const usePoints = !this.props.isEstimatedInHours;
		const estimateSharedMessage = formatMessage(
			{id: 'common.estimate_shared'},
			{
				total: usePoints ? forecast : Util.convertMinutesToFullHour(forecast * 60, this.props.intl),
				persons: taskAssignedNames,
			}
		);
		const showWarning = showOverrunPredictionWarning(task);
		const predictionInputExtension = showWarning ? (
			<PredictionPopupInputExtension
				task={task}
				modelScore={this.props.lowHighModelScore}
				showWarning={showWarning}
				intl={this.props.intl}
			/>
		) : null;

		if (!col.checked && col.maintainSpace) {
			return null;
		}
		switch (colName) {
			case 'selector':
				return (
					<CheckBox
						id={task.id}
						key={'selector'}
						isDisabled={task.project.status === 'DONE'}
						isChecked={this.props.selected}
						isFocusable={task.project.status !== 'DONE'}
						onClick={this.handleSelected.bind(this)}
						cy={'selector-checkbox'}
						userpilot={'bulk-update-checkbox'}
					/>
				);
			case 'project-id':
				return <DeprecatedProjectIndicatorJS project={taskProject} />;
			case 'project-name':
				return (
					<div className={'project-group'}>
						<div className={'project-name'}>
							{disableActions ? (
								<div className={'td-text no-click'} tabIndex={'0'} title={taskProject.name}>
									{taskProject.name}
								</div>
							) : (
								<a
									className={'td-text'}
									href={this.getProjectNavigationString(taskProject)}
									tabIndex={'0'}
									title={taskProject.name}
								>
									{taskProject.name}
								</a>
							)}
						</div>
					</div>
				);
			case 'task-name':
				// 15px icon width, 2px margin
				const iconWidth = 17;
				const warnings = Util.GetTaskWarnings(task, this.props.intl);
				const taskIdWidth = !!taskId ? 50 : 0;
				const selectorWidth = !!selector ? 50 : 0;
				const totalIconWidth = this.getNumberOfIconsForTask(task) * iconWidth;
				const groupPadding = 16;

				// Parent task name
				const parentIsEpic = task.parentTask && task.parentTask.taskType ? task.parentTask.taskType === 'EPIC' : false;
				const parentTaskName = task.project.useTaskHierarchy && !parentIsEpic && task.parentTask?.name;

				return (
					<div
						className={'task-group' + (this.props.showProjectIndicator ? ' not-first' : '')}
						style={{
							maxWidth: taskCellMaxWidth + selectorWidth,
							minWidth: taskCellMaxWidth + selectorWidth,
						}}
					>
						{selector ? selector : null}
						{taskId ? taskId : null}
						{task && warnings && warnings[0] ? (
							<div className={'warning-icon'}>
								<WarningIcon
									title={warnings[0].message}
									danger={warnings[0].color === 'red'}
									customWidth={15}
									customHeight={15}
								/>
							</div>
						) : null}
						{task && task.highPriority ? <div className={'high-priority-flag'} /> : null}
						{task && task.blocked ? <div className={'blocked-icon'} /> : null}
						{task && task.bug ? <div className={'bug-icon'} /> : null}
						<div
							className={'task-name' + (task && task.highPriority ? ' with-flag' : '')}
							style={{
								maxWidth: taskCellMaxWidth
									? taskCellMaxWidth - selectorWidth - taskIdWidth - totalIconWidth - groupPadding
									: '',
								minWidth: taskCellMaxWidth
									? taskCellMaxWidth - selectorWidth - taskIdWidth - totalIconWidth - groupPadding
									: '',
							}}
						>
							{disableActions ? (
								<div
									className={'td-text'}
									tabIndex={'0'}
									onKeyDown={
										task
											? e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null)
											: null
									}
									onClick={task ? e => this.onTaskClick(e, task) : null}
									title={task.name}
								>
									{parentTaskName && <TaskParentName>{parentTaskName}</TaskParentName>}
									{task.name}
								</div>
							) : (
								<a
									className={'td-text'}
									data-cy="task-name"
									href={
										window.location.href +
										(window.location.href.endsWith('/')
											? 'T' + task.companyTaskId
											: '/T' + task.companyTaskId)
									}
									tabIndex={'0'}
									onKeyDown={
										task
											? e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null)
											: null
									}
									onClick={task ? e => this.onTaskClick(e, task) : null}
									title={task.name}
								>
									{parentTaskName && <TaskParentName>{parentTaskName}</TaskParentName>}
									{task.name}
								</a>
							)}
						</div>
					</div>
				);
			case 'forecast': //estimate
				const forecastValue = forecast;
				if (isShared) {
					const personForecastValueInHoursShared = Math.round((forecast / numberOfPerson) * 100) / 100;

					if (usePoints) {
						return (
							<NumericTile
								task={task}
								id={task.id}
								key={'estimate'}
								value={personForecastValueInHoursShared}
								unit={this.estimationUnit}
								isV2={true}
								cy={'forecast-tile'}
								groupedValues={isShared}
								tooltip={isShared}
								infoText={
									isShared
										? '' +
										  formatMessage(
												{id: 'common.estimate_shared'},
												{total: personForecastValueInHoursShared, persons: taskAssignedNames}
										  )
										: ''
								}
							/>
						);
					} else {
						return (
							<NumericTiles
								id={task.id}
								key={'estimate'}
								valueInMinutes={personForecastValueInHoursShared * 60}
								asterisk={isShared}
								infoText={formatMessage(
									{id: 'common.estimate_shared'},
									{
										total: Util.convertMinutesToFullHour(forecast * 60, this.props.intl),
										persons: taskAssignedNames,
									}
								)}
								cy={'forecast-tile'}
							/>
						);
					}
				} else {
					return this.props.lazyDataFetched && usePoints ? (
						<AffixedInputWrapper
							onClick={e => e.focus()}
							disabled={disableActions || this.props.isJiraProject}
							customClassName={'hours-input'}
							value={forecastValue}
							callback={value => this.handleEstimationChanges('estimateInput', value)}
							affix={formatMessage({id: 'common.points.short'})}
						/>
					) : this.props.lazyDataFetched ? (
						<div className="hours-input-wrapper">
							{predictionInputExtension}
							<HoursInput
								value={forecastValue}
								mutation={v => this.handleEstimationChanges('estimateInput', v * 60)}
								onClick={e => e.focus()}
								cy={'estimate-input'}
								disabled={disableActions || this.props.isJiraProject}
								withInputExtension={showWarning}
							/>
						</div>
					) : (
						<NumericTiles
							id={task.id}
							valueInMinutes={forecastValue * 60}
							asterisk={isShared}
							infoText={isShared ? estimateSharedMessage : null}
							cy={'forecast-tile'}
						/>
					);
				}
			case 'done-percentage':
				const progressRegistrationPopup =
					!disableActions && !this.props.isClientUser ? <TaskProgressRegistrationPopup task={task} /> : null;

				return task.done ? (
					<div className={'done-check-mark'} />
				) : taskProject.manualProgressOnTasksEnabled ? (
					<NumericTileV2
						id={task.id}
						key={'progress'}
						popup={progressRegistrationPopup}
						value={task.progressDetails?.progress || 0}
						unit={UNIT.PERCENTAGE}
						cy={'done-percentage-tile'}
					/>
				) : task.progress !== undefined ? (
					<NumericTile
						task={task}
						id={task.id}
						key={'forecast'}
						value={task.progress}
						unit={'%'}
						isV2={true}
						cy={'done-percentage-tile'}
					/>
				) : (
					<TextTile textAlign="right" id={task.id} key={'time-entries'} value={'-'} />
				);
			case 'assigned-role':
				return (
					<AssignedTableTile
						disabled={disableActions}
						assignableRoles={this.props.assignableRoles}
						assignedRole={task.role}
						assignRole={this.assignRoleToTask.bind(this)}
						roleAssigned={task.role ? task.role.name : ''}
						useTableStyling={true}
						isMultiSelect={true}
						id={task.id}
						key={'assigned-role'}
						task={task}
						maxDisplayed={1}
						cy={'assigned-role'}
						viewer={this.props.viewer}
						noClear={true}
						closeOnChoice={true}
						//wideExpanded={true}
					/>
				);
			case 'assigned-person':
				return (
					<AssignedTableTile
						disabled={disableActions}
						assignablePersons={
							this.props.assignablePersons
								? this.props.assignablePersons
								: task.project && task.project.projectPersons
								? task.project.projectPersons.edges
								: []
						}
						assignedPersons={task.assignedPersons}
						assignPersonOnlyDiff={true}
						assignPerson={this.assignPersonsToTask.bind(this)}
						unassignPerson={this.unassignPersonFromTask.bind(this)}
						useTableStyling={true}
						isMultiSelect={true}
						id={task.id}
						key={'assigned-person'}
						task={task}
						maxDisplayed={this.props.entityId === 'all-tasks' ? 2 : 4}
						cy={'assigned-person'}
						viewer={this.props.viewer}
						suggestedPersonRoleFilter={task.role}
						isClientUser={this.props.isClientUser}
						//wideExpanded={true}
					/>
				);
			case 'task-owner':
				return (
					<AssignedTableTile
						disabled={disableActions || !task.project.useTaskOwner || this.props.isClientUser}
						assignablePersons={
							this.props.assignablePersons
								? this.props.assignablePersons
								: task.project && task.project.projectPersons
								? task.project.projectPersons.edges
								: []
						}
						assignedPersons={task.owner ? [task.owner] : []}
						assignPersonOnlyDiff={true}
						assignPerson={this.setOwner.bind(this, false)}
						unassignPerson={this.setOwner.bind(this, false, null)}
						useTableStyling={true}
						isMultiSelect={false}
						cy="task-modal-owner-dropdown"
						viewer={this.props.viewer}
						task={task}
						useSmallerStyling={true}
						withAddIcon={false}
						closeOnChoice={true}
						wideExpanded={true}
						isClientUser={this.props.isClientUser}
						isOwner={true}
					/>
				);
			case 'deadline': {
				return (
					<div className={'task-deadline'}>
						<div className={'task-deadline-text'}>{this.getDeadlineTextForTask(task)}</div>
					</div>
				);
			}
			case 'non-billable-time-entries':
				if (task.timeRegistrations) {
					const minutesRegistered = (groupByPerson ? personNonBillableTimeRegs : totalNonBillableTime) * 60;
					return <NumericTiles id={task.id} valueInMinutes={minutesRegistered} cy={'time-entries-tile'} />;
				}
				break;
			case 'billable-time-entries':
				if (task.timeRegistrations) {
					const minutesRegistered = (groupByPerson ? personBillableTimeRegs : totalBillableTime) * 60;
					return <NumericTiles id={task.id} valueInMinutes={minutesRegistered} cy={'time-entries-tile'} />;
				}
				break;
			case 'time-entries':
				const timeRegPopup =
					!disableActions && !this.props.isClientUser ? (
						<TimeRegPopup viewer={this.props.viewer} projectId={taskProject.id} task={task} harvestTask={false} />
					) : null;
				if (task.timeRegistrations) {
					const minutesRegistered = (groupByPerson ? personTimeRegs : totalTime) * 60;
					return (
						<NumericTiles
							id={task.id}
							valueInMinutes={minutesRegistered}
							timeRegPopup={timeRegPopup}
							cy={'time-entries-tile'}
						/>
					);
				} else {
					return <TextTile textAlign="right" id={task.id} key={'time-entries'} value={'-'} />;
				}
			case 'remaining':
				if (this.props.task.timeLeft === undefined) {
					return <TextTile textAlign="right" id={task.id} key={'remaining'} value={'-'} />;
				}
				if (usePoints) {
					const remainingValue = Math.round(this.state.remainingInput * 100) / 100;
					const personRemainingValue = groupByPerson
						? Math.round((remainingValue / numberOfPerson) * 100) / 100
						: null;
					return (
						<TaskTableInputField
							hideLabel={true}
							invalidInput={!this.validateRemaining()}
							id={task.id}
							key={'remaining'}
							cy={'remaining'}
							task={task}
							disabled={
								disableActions ||
								this.props.isJiraProject ||
								taskProject.remainingAutoCalculated ||
								task.statusColumnV2.category === 'DONE' ||
								isShared
							}
							minNumber={0}
							placeholder={'0'}
							companyEstimationUnit={this.estimationUnit}
							type="number"
							alignText={'right'}
							onBlur={this.updateTaskRemaining.bind(this)}
							value={groupByPerson ? personRemainingValue || '' : remainingValue || ''}
							onChange={v => this.handleInputChange('remainingInput', v)}
							groupedValues={isShared && remainingValue > 0}
							tooltipMessage={
								isShared && remainingValue > 0
									? formatMessage(
											{id: 'common.remaining_shared'},
											{total: remainingValue, persons: taskAssignedNames}
									  )
									: ''
							}
						/>
					);
				} else {
					const remainingValue = this.state.remainingInput;
					const remainingInMinutes = isShared
						? Math.round((remainingValue / numberOfPerson) * 100) / 100
						: this.state.remainingInput;
					if (
						disableActions ||
						this.props.isJiraProject ||
						taskProject.remainingAutoCalculated ||
						task.statusColumnV2.category === 'DONE' ||
						isShared
					) {
						return (
							<NumericTiles
								infoText={
									isShared && remainingValue > 0
										? formatMessage(
												{id: 'common.remaining_shared'},
												{
													total: Util.convertMinutesToFullHour(remainingValue, this.props.intl),
													persons: taskAssignedNames,
												}
										  )
										: ''
								}
								id={task.id}
								valueInMinutes={remainingInMinutes}
								asterisk={isShared}
							/>
						);
					} else {
						return (
							<HoursInput
								customClassName={'hours-input'}
								value={remainingInMinutes / 60}
								mutation={v => this.handleRemainingChange(v * 60)}
								onClick={e => e.focus()}
								disabled={isShared}
							/>
						);
					}
				}

			case 'status':
				if (!this.props.lazyDataFetched || !taskProject || !taskProject.statusColumnsV2) {
					return (
						<TextTile
							textAlign={col.align}
							task={task}
							id={task.id}
							key={'status'}
							value={task.statusColumnV2 ? task.statusColumnV2.name : ''}
						/>
					);
				} else {
					return (
						<Dropdown
							focusOnClick={true}
							optionClickEvent={true}
							onChange={this.onStatusColumnChange.bind(this)}
							options={cloneDeep(taskProject.statusColumnsV2.edges)
								.sort((a, b) => {
									const comparisonResult = a.node.order - b.node.order;
									if (comparisonResult > 0) {
										return 1;
									} else if (comparisonResult < 0) {
										return -1;
									} else {
										return 0;
									}
								})
								.map(statusColumn => {
									return {
										label: statusColumn.node.name,
										value: statusColumn.node.id,
										logo: statusColumn.node.jiraStatusId ? 'jira-logo' : undefined,
									};
								})}
							value={task.statusColumnV2.id}
							hideLabel={true}
							disabled={disableActions || this.props.isClientUser}
							listDataCy={'status-list'}
							buttonCy={'status-arrow'}
							inputCy={'status-input'}
							restrictWidth={true}
							//borderlessOnTop={false}
							customHeight={30}
						/>
					);
				}
			case 'over-forecast':
				if (task.timeRegistrations) {
					const forecastInHours =
						task.project.isEstimatedInHours || this.props.isEstimatedInHours
							? forecast
							: (forecast * (task.project.minutesPerEstimationPoint || this.props.minutesPerEstimationPoint)) /
							  60;
					const overForecastValue = forecastInHours - totalTime;
					const overForecastPersonValue = forecastInHours / numberOfPerson - personTimeRegs;
					return (
						<NumericTiles
							id={task.id}
							key={'over-forecast'}
							valueInMinutes={(groupByPerson ? overForecastPersonValue : overForecastValue) * 60}
							cy={'over-forecast-tile'}
						/>
					);
				} else {
					return <TextTile textAlign="right" id={task.id} key={'over-forecast'} value={'-'} />;
				}
			case 'price':
				const forecastPriceValue = formatNumber(ProjectUtil.getEstimatedPrice(task), {
					format: 'always_two_decimal',
				});
				const forecastPersonPriceValue = formatNumber(Number(ProjectUtil.getEstimatedPrice(task) / numberOfPerson), {
					format: 'always_two_decimal',
				});
				return (
					<NumericTile
						id={task.id}
						key={
							'price' // estimateForecastPrice
						}
						placeUnitBeforeValue={this.props.placeUnitBeforeValue}
						task={task}
						value={groupByPerson ? forecastPersonPriceValue : forecastPriceValue}
						unit={this.props.currencySymbol}
						isV2={true}
						cy={'price-tile'}
						groupedValues={isShared}
						tooltip={isShared}
						infoText={
							isShared
								? formatMessage(
										{id: 'common.price_shared'},
										{total: forecastPriceValue, persons: taskAssignedNames}
								  )
								: ''
						}
					/>
				);
			case 'actual-price':
				if (task.timeRegistrations) {
					const currentPriceValue = formatNumber(ProjectUtil.getActualPriceForTask(task), {
						format: 'always_two_decimal',
					});

					const currentPersonPriceValue = formatNumber(
						task.timeRegistrations && task.billable
							? getTotalFromTimeRegs(
									task.timeRegistrations.edges,
									this.props.groupPersonId,
									timeReg => timeReg.node.price,
									this.props.groupByRole
							  )
							: 0,
						{format: 'always_two_decimal'}
					);

					return (
						<NumericTile
							id={task.id}
							key={
								'actual-price' // Current price
							}
							placeUnitBeforeValue={this.props.placeUnitBeforeValue}
							task={task}
							value={groupByPerson ? currentPersonPriceValue : currentPriceValue}
							unit={this.props.currencySymbol}
							isV2={true}
							cy={'actual-price-tile'}
							groupedValues={isShared}
							tooltip={isShared}
							infoText={
								isShared ? formatMessage({id: 'project_sprints.person_multiple_assigned_price_info'}) : ''
							}
						/>
					);
				} else {
					return <TextTile textAlign="right" id={task.id} key={'actual-price'} value={'-'} />;
				}
			case 'planned-cost':
				const plannedCostValueToShow = groupByPerson
					? formatNumber(Number(ProjectUtil.getPlannedCostForTask(task) / numberOfPerson), {
							format: 'always_two_decimal',
					  })
					: formatNumber(ProjectUtil.getPlannedCostForTask(task), {
							format: 'always_two_decimal',
					  });

				return (
					<NumericTile
						id={task.id}
						key={'planned-cost'}
						placeUnitBeforeValue={this.props.placeUnitBeforeValue}
						task={task}
						value={plannedCostValueToShow}
						unit={this.props.currencySymbol}
						isV2={true}
						cy={'planned-cost-tile'}
						groupedValues={isShared}
						tooltip={isShared}
					/>
				);

			case 'actual-cost':
				const actualCostValueToShow = groupByPerson
					? formatNumber(Number(ProjectUtil.getActualCostForTask(task) / numberOfPerson), {
							format: 'always_two_decimal',
					  })
					: formatNumber(ProjectUtil.getActualCostForTask(task), {
							format: 'always_two_decimal',
					  });

				return (
					<NumericTile
						id={task.id}
						key={'actual-cost'}
						placeUnitBeforeValue={this.props.placeUnitBeforeValue}
						task={task}
						value={actualCostValueToShow}
						unit={this.props.currencySymbol}
						isV2={true}
						cy={'actual-cost-tile'}
						groupedValues={isShared}
						tooltip={isShared}
					/>
				);
			case 'date':
				//return <TextTile textAlign={col.align} task={task} id={task.id} key={'date'} value={dateString} />;
				return (
					<div className="phase-deadline-select double">
						<DatePicker
							buttonCy={'date-button'}
							disabled={disableActions}
							id={task.id}
							task={task}
							isNewDateRange={true}
							startDate={Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay)}
							endDate={Util.CreateNonUtcMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay)}
							handleDateRangeChange={this.handleDateRangeChange.bind(this)}
							clearable={true}
							clearBothDates={this.clearDates.bind(this)}
							startFrom={Util.getStartFrom(task, this.props.intl)}
							endFrom={Util.getEndFrom(task, this.props.intl)}
							datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
							colorInherited={true}
							fontWeight={'normal'}
							collapseOnWheel={true}
							compactShowYear={this.props.compactShowYear}
							disabledDates={isDateDisabled}
						/>
					</div>
				);
			case 'sprint':
				if (!this.props.lazyDataFetched) {
					return (
						<TextTile
							textAlign={col.align}
							task={task}
							id={task.id}
							key={'sprint'}
							value={task.sprint ? task.sprint.name : formatMessage({id: 'project_sprints.backlog'})}
						/>
					);
				}
				const sprintOptions = [];
				sprintOptions.push({label: formatMessage({id: 'project_sprints.backlog'}), value: null});
				if (this.props.sortedSprints) {
					this.props.sortedSprints.forEach(sprintOption => {
						sprintOptions.push({
							label: sprintOption.label,
							value: sprintOption.value,
						});
					});
				}
				return (
					<Dropdown
						focusOnClick={true}
						optionClickEvent={true}
						onChange={this.onSprintChange.bind(this)}
						options={sprintOptions}
						value={
							task.sprint ? (this.props.viewer.project ? task.sprint.id : task.sprint.projectGroupSprintId) : null
						}
						hideLabel={true}
						disabled={disableActions}
						listDataCy={'sprint-list'}
						buttonCy={'sprint-arrow'}
						inputCy={'sprint-input'}
						customHeight={30}
					/>
				);
			//<TextTile textAlign={col.align} task={task} id={task.id} key={'sprint'} value={task.sprint ? task.sprint.name : formatMessage({id: 'project_sprints.backlog'})} />;
			case 'phase':
				if (!this.props.lazyDataFetched) {
					return (
						<TextTile
							textAlign={col.align}
							task={task}
							id={task.id}
							key={'sprint'}
							value={task.phase ? task.phase.name : formatMessage({id: 'project_scopes.no-scope'})}
						/>
					);
				}
				const phaseOptions = [];
				phaseOptions.push({label: formatMessage({id: 'project_scopes.no-scope'}), value: null});
				taskProject.phases?.edges.forEach(phase => {
					phaseOptions.push({
						label: phase.node.name,
						value: phase.node.id,
					});
				});
				return (
					<Dropdown
						focusOnClick={true}
						optionClickEvent={true}
						onChange={this.onPhaseChange.bind(this)}
						options={phaseOptions}
						value={task.phase ? task.phase.id : null}
						hideLabel={true}
						disabled={disableActions || this.props.isClientUser}
						listDataCy={'sprint-list'}
						buttonCy={'sprint-arrow'}
						inputCy={'sprint-input'}
						customHeight={30}
					/>
				);
			//<TextTile textAlign={col.align} task={task} id={task.id} key={'phase'} value={task.phase ? task.phase.name : formatMessage({id: 'project_scopes.no-scope'})} />;
			case 'labels':
				return <LabelGroup labels={task.taskLabels} noWrap={true} />;
			case 'approved':
				if (col.hide) {
					return null;
				} else {
					return (
						<Dropdown
							customClasses={'approved-dropdown'}
							focusOnClick={true}
							optionClickEvent={true}
							onChange={option => this.onTaskApprovalChange(task, option)}
							options={[
								{
									label: formatMessage({id: 'common.yes'}),
									value: true,
								},
								{
									label: formatMessage({id: 'common.no'}),
									value: false,
								},
							]}
							value={task.approved}
							hideLabel={true}
							disabled={disableActions || !hasPermission(PERMISSION_TYPE.PHASE_UPDATE)}
							listDataCy={'approved-list'}
							buttonCy={'approved-arrow'}
							inputCy={'approved-input'}
							restrictWidth={true}
							customHeight={30}
						/>

						// 	cy={'approved-checkbox'}
					);
				}
			case 'chip-right':
				return (
					<div className={'last-cell' + (groupByPerson ? ' grouped' : '')} style={{height: this.props.rowHeight}}>
						<div className="chip-container ">
							<div
								className={
									'chip-div' +
									(!this.props.selected && !this.props.isHighlighted && this.props.noBorderRight
										? ' no-border-right'
										: '')
								}
							/>
							<div className={'chip ' + (!this.props.emptyPhase ? 'has-phase' : '')} />
						</div>
					</div>
				);
			default:
				return null;
		}
	}

	render() {
		let rowElements = [];
		const projectStatus = this.props.task && this.props.task.project ? this.props.task.project.status : '';
		const disabled =
			this.props.projectLocked || this.props.disabled || projectStatus === 'HALTED' || projectStatus === 'DONE';
		if (this.props.task) {
			const task = this.props.task;
			const {formatMessage} = this.props.intl;
			this.estimationUnit =
				task && task.project
					? task.project.estimationUnit === 'HOURS'
						? formatMessage({id: 'common.hours.short'})
						: formatMessage({id: 'common.points.short'})
					: this.props.isEstimatedInHours
					? formatMessage({id: 'common.hours.short'})
					: formatMessage({id: 'common.points.short'});

			this.warnings = this.props.task ? Util.GetTaskWarnings(task, this.props.intl) : null;
			this.path =
				(window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/') +
				'T' +
				task.companyTaskId;
			let taskId;
			let selector;
			rowElements = [];
			this.props.availableColumns.forEach(col => {
				if (col.checked || col.maintainSpace) {
					if (col.name === 'task-id') {
						taskId = (
							<div
								className={col.name}
								data-cy={col.name}
								key={col.name}
								style={{
									maxWidth: col.maxWidth ? col.maxWidth : '',
									minWidth: col.minWidth,
								}}
								ref={node => (this.taskIdNode = node)}
								tabIndex={'0'}
								onKeyDown={
									task ? e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null) : null
								}
								onClick={task ? e => this.onTaskClick(e, task, 'id') : null}
							>
								{'T' + task.companyTaskId}
							</div>
						);
						return;
					}
					if (col.name === 'selector') {
						selector = (
							<CheckBox
								id={task.id}
								key={'selector'}
								isDisabled={task.project.status === 'DONE'}
								isChecked={this.props.selected}
								isFocusable={task.project.status !== 'DONE'}
								//onClick={this.handleSelected.bind(this)}
								onClick={this.handleSelected.bind(this)}
								cy={'selector-checkbox'}
								userpilot={'bulk-update-checkbox'}
							/>
						);
						return;
					}
					rowElements.push({
						element: this.getElemForCol(col, taskId, selector),
						minWidth: col.minWidth,
						maxWidth: col.maxWidth ? col.maxWidth : '',
						justifyContent: col.align,
					});
				}
			});
			const taskCellMaxWidth = this.props.taskCellMaxWidth;
			const content = (
				<div
					onBlur={this.onRowBlur.bind(this)}
					className={
						'table-row-v3' +
						(this.props.isHighlighted ? ' highlight' : '') +
						(this.props.isNewTask ? ' animated pulse 0.2s' : '') +
						(this.props.selected ? ' selected ' : '') +
						(disabled ? ' locked' : '') +
						(this.props.parentType ? ' ' + this.props.parentType : '') +
						(this.props.task && this.props.task.favoured && this.props.task.approved ? ' favoured' : '') +
						(this.props.dragActive ? ' no-hover' : '')
					}
					data-cy={'task-scoping-table-row-v3'}
					ref={this.props.innerRef}
					style={{
						height: this.props.rowHeight,
					}}
					onContextMenu={e => this.props.onContextMenu(this.props.task, e)}
				>
					<div className={'drag-handle' + (disabled || this.props.disableDragHandle ? ' disabled' : '')} />
					{rowElements.map((obj, index) => (
						<div
							key={index}
							className={
								'elem-wrapper' +
								(disabled ? ' locked' : '') +
								(!obj.maxWidth ? ' growable' : '') +
								(index === rowElements.length - 1 ? ' last' : '')
							}
							style={{
								height: this.props.rowHeight,
								minWidth: obj.minWidth,
								maxWidth: obj.maxWidth ? obj.maxWidth : taskCellMaxWidth,
								justifyContent: obj.justifyContent,
							}}
						>
							{obj.element}
						</div>
					))}
					{!task.approved ? null : (
						<div className={'star-option'}>
							<StarTask
								disabled={this.props.selectionMode}
								task={task}
								parentId={this.props.viewer.id}
								favoriteTaskList={this.props.favoriteTaskList}
							/>
						</div>
					)}
					{disabled || !this.props.lazyDataFetched || this.props.selectionMode ? null : (
						<div
							data-cy="dot-options"
							className={'dot-options' + (this.props.contextMenuTaksId === task.id ? ' clicked' : '')}
							onClick={this.toggleContextMenu.bind(this)}
						/>
					)}
				</div>
			);

			return this.props.withMargin ? <div className={'table-row-v3-margin-wrapper'}>{content}</div> : content;
		}
	}
}

TableRow.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	task: PropTypes.object,
	index: PropTypes.number,
	tableRowElems: PropTypes.array,
	rowHeight: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	shouldHaveBorderLeft: PropTypes.bool,
	onRowSelected: PropTypes.func,
	noBorderRight: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	selected: PropTypes.bool,
};
TableRow.defaultProps = {
	lazyDataFetched: true,
};
export default injectIntl(TableRow);
