import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexRow, Modal, DropdownList, Text, ButtonDropdownField } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';
import Util from '../../../forecast-app/shared/util/util';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
const AssociateClientToProjectModal = ({ projectId, closeModal, viewer }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { formatMessage } = useIntl();
    const [clientId, setClientId] = useState(undefined);
    const [selectedClient, setSelectedClient] = useState((_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.find(client => { var _a; return ((_a = client === null || client === void 0 ? void 0 : client.node) === null || _a === void 0 ? void 0 : _a.id) === clientId; }));
    const clients = (_f = (_e = (_d = viewer.company) === null || _d === void 0 ? void 0 : _d.clients) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(edge => edge === null || edge === void 0 ? void 0 : edge.node);
    const onSuccess = () => {
        closeModal();
        showModal({
            type: MODAL_TYPE.ADD_PROJECT_BILL_TO,
            projectId: projectId,
        });
    };
    const associateClientToProject = () => {
        const project = { id: projectId };
        Util.CommitMutation(UpdateProjectMutation, { project: project, clientId: clientId }, onSuccess);
    };
    const handleSelectClient = clientId => {
        var _a, _b, _c;
        setClientId(clientId);
        setSelectedClient((_c = (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.clients) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.find(client => { var _a; return ((_a = client === null || client === void 0 ? void 0 : client.node) === null || _a === void 0 ? void 0 : _a.id) === clientId; }));
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: 'settings_finance.select_client' })),
        React.createElement(Modal.Content, null,
            React.createElement(React.Fragment, null,
                React.createElement(Text, { size: '1' },
                    React.createElement("b", null, formatMessage({ id: 'settings_finance.select_client' }))),
                React.createElement(DropdownList, null,
                    React.createElement(DropdownList.Trigger, null,
                        React.createElement(ButtonDropdownField, null, selectedClient
                            ? (_g = selectedClient.node) === null || _g === void 0 ? void 0 : _g.name
                            : formatMessage({ id: 'settings_finance.select_client' }))),
                    React.createElement(DropdownList.Content, { offset: 5, align: 'start' }, clients === null || clients === void 0 ? void 0 : clients.map(client => (React.createElement(DropdownList.Item, { key: client === null || client === void 0 ? void 0 : client.id, onClick: () => handleSelectClient(client === null || client === void 0 ? void 0 : client.id) },
                        React.createElement(FlexRow, null, client === null || client === void 0 ? void 0 : client.name)))))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: associateClientToProject }, formatMessage({ id: 'common.associate' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))));
};
export const AssociateClientToProjectModalQuery = graphql `
	query AssociateClientToProjectModalQuery {
		viewer {
			actualPersonId
			component(name: "AssociateClientToProjectModal")
			...AssociateClientToProjectModal_viewer
		}
	}
`;
export default createFragmentContainer(AssociateClientToProjectModal, {
    viewer: graphql `
		fragment AssociateClientToProjectModal_viewer on Viewer {
			id
			company {
				id
				clients(first: 100000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
