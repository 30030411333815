import React from 'react';
import { Modal, Text, Frame, FlexRow, FlexColumn } from '@forecast-it/design-system';
import { useIntl, FormattedHTMLMessage } from 'react-intl';
const DisableIntegrationWarningModal = ({ integration, removeCallback, closeModal, }) => {
    const { formatMessage } = useIntl();
    const lowerCasedIntegrationName = integration.toLowerCase();
    const removeHandler = () => {
        removeCallback();
        closeModal();
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, formatMessage({ id: `settings.app_catalog.${lowerCasedIntegrationName}.warning_modal_title` })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null,
                React.createElement(FormattedHTMLMessage, { id: `settings.app_catalog.${lowerCasedIntegrationName}.warning_modal_desc_1` })),
            React.createElement(Frame, null,
                React.createElement(FlexColumn, { gap: 'm' },
                    React.createElement(FlexRow, { gap: 'm' },
                        React.createElement("ul", null,
                            React.createElement("li", null, formatMessage({
                                id: `settings.app_catalog.${lowerCasedIntegrationName}.warning_modal_desc_2`,
                            })),
                            React.createElement("li", null, formatMessage({
                                id: `settings.app_catalog.${lowerCasedIntegrationName}.warning_modal_desc_3`,
                            })))))),
            React.createElement(Text, null, formatMessage({ id: 'settings.app_catalog.warning_modal_desc_4' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: removeHandler }, formatMessage({ id: 'common.remove' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))));
};
export default DisableIntegrationWarningModal;
