import {DATE_FORMAT_DAY, DependencyType, SCHEDULING_VIEW} from '../../constants';
import {
	allowAllocationEdit,
	getMomentFromCanvasTimelineDate,
	isProjectDoneOrHalted,
	isTimeOffAllocation,
} from './canvas-timeline/canvas_timeline_util';
import Util from '../../forecast-app/shared/util/util';
import {getCantDeleteTaskMessage} from '../../forecast-app/shared/util/TaskUtil';
import {dispatch, EVENT_ID} from '../../containers/event_manager';
import {onDependencyDeleted} from './projects-scheduling/projects_scheduling_dependencies';
import {
	createAllocation,
	deleteAllocation,
	deletePlaceholderAllocation,
	duplicatePlaceholderAllocationMutation,
	duplicateTask,
	splitPlaceholderAllocationMutation,
	updateAllocation,
	updateTask,
} from '../scheduling/scheduling_mutations';
import DeleteTaskMutation from '../../mutations/delete_task_mutation';
import {handleProjectsSchedulingMutation} from './projects-scheduling/projects_scheduling_mutation_success';
import {handlePeopleSchedulingMutation} from './people-scheduling/people_scheduling_mutation_success';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import * as tracking from '../../tracking';
import moment from 'moment';
import {canApproveAllocation, hasPermission, isClientUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {createToast} from '../../forecast-app/shared/components/toasts/toast';
import {handlePlaceholdersSchedulingMutation} from './placeholders-scheduling/PlaceholdersSchedulingMutationSuccess';
import {handleTransferToPerson} from './actions/handle_transfer_to_person';
import {handlePlaceholdersSchedulingStaffingOperation} from './placeholders-scheduling/PlaceholdersSchedulingStaffingSuccess';
import {generateStaffingId} from './placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {handleCapacityOverviewSchedulingMutation} from './capacity-overview/CanvasCapacityOverviewMutationSuccess';
import {trackComplexEvent, trackEvent} from '../../tracking/amplitude/TrackingV2';
import {handleAssignToPerson} from './actions/handle_assign_to_person';
import ValidateCanUserDeleteTaskMutation from '../../mutations/ValidateCanUserDeleteTaskMutation';
import DataManager from './DataManager';

// method used for successful mutations
const onSuccess = (pageComponent, res) => {
	const {schedulingView} = pageComponent.props;
	const args = {EVENT_FROM_SCHEDULING: true};

	switch (schedulingView) {
		case SCHEDULING_VIEW.PROJECTS:
			handleProjectsSchedulingMutation(pageComponent, res, args);
			break;
		case SCHEDULING_VIEW.PEOPLE:
			handlePeopleSchedulingMutation(pageComponent, res, args);
			break;
		case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			handleCapacityOverviewSchedulingMutation(pageComponent, res, args);
			break;
		case SCHEDULING_VIEW.PLACEHOLDERS:
			const {staffingModeActive} = pageComponent.state;

			if (staffingModeActive) {
				handlePlaceholdersSchedulingStaffingOperation(pageComponent, res, args);
			} else {
				handlePlaceholdersSchedulingMutation(pageComponent, res, args);
			}

			break;
		default:
			break;
	}
};

// ALLOCATION CONTEXT MENU

const splitAllocation = async (pageComponent, allocation, canvasDate) => {
	const {schedulingView} = pageComponent.props;
	const {
		id,
		personId,
		endYear,
		endMonth,
		endDay,
		idleTimeId,
		projectId,
		projectGroupId,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
		sunday,
		description,
		registerTime,
		isSoft,
	} = allocation;

	const splitDate = getMomentFromCanvasTimelineDate(canvasDate);

	// end the current allocation on the previous day
	const splitDateMoment = moment(splitDate);
	const currentAllocationEndDate = moment(splitDate).subtract(1, 'day');

	if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS && pageComponent.state.staffingModeActive) {
		const updatedAllocation = {
			...allocation,
			endDate: currentAllocationEndDate.format(DATE_FORMAT_DAY),
			endYear: currentAllocationEndDate.year(),
			endMonth: currentAllocationEndDate.month() + 1,
			endDay: currentAllocationEndDate.date(),
		};

		const newAllocation = {
			...allocation,
			startDate: splitDateMoment.format(DATE_FORMAT_DAY),
			startYear: splitDateMoment.year(),
			startMonth: splitDateMoment.month() + 1,
			startDay: splitDateMoment.date(),
			id: generateStaffingId(),
			parentId: allocation.parentId || allocation.id,
		};

		const res = {
			splitAllocation: {
				allocations: [updatedAllocation, newAllocation],
			},
		};

		onSuccess(pageComponent, res);
	} else {
		// update the current allocation
		const currentAllocationData = {
			id,
			endYear: currentAllocationEndDate.year(),
			endMonth: currentAllocationEndDate.month() + 1,
			endDay: currentAllocationEndDate.date(),
		};

		let updatedAllocationPromise = new Promise((resolve, _) => {
			updateAllocation(currentAllocationData, res => resolve(res));
		});

		// create identical allocation that starts on the splitDate
		const newAllocationData = {
			personId,
			startYear: splitDate.year(),
			startMonth: splitDate.month() + 1,
			startDay: splitDate.date(),
			endYear,
			endMonth,
			endDay,
			idleTimeId,
			projectId,
			projectGroupId,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			description,
			registerTime,
			isSoft,
		};

		let newAllocationPromise = new Promise((resolve, _) => {
			createAllocation(newAllocationData, res => resolve(res));
		});

		// only redraw after both mutations succeded for a better ux
		const res = await Promise.all([updatedAllocationPromise, newAllocationPromise]);
		onSuccess(pageComponent, res[0]);
		onSuccess(pageComponent, res[1]);

		tracking.trackEvent('Split Allocation from the context Menu');
		trackComplexEvent('Allocation', 'Split', {location: 'From Context Menu'});
	}
};

const editAllocation = (pageComponent, allocation) => {
	const {schedulingOptions} = pageComponent.state;
	const data = pageComponent.getData();
	const person = data.persons.find(person => person.id === allocation.personId);

	showModal({
		type: MODAL_TYPE.CANVAS_CREATE,
		projectGroups: data.projectGroups,
		projects: data.projects,
		idleTimes: data.idleTimes,
		projectPersons: data.projectPersons,
		actualPersonId: data.viewer.actualPersonId,
		persons: data.persons,
		selectedPersonId: person.id,
		phases: data.phases,
		company: data.company,
		holidayCalendars: data.holidayCalendars,
		holidayCalendarEntries: data.holidayCalendarEntries,
		allocation: allocation,
		roles: data.roles,
		teams: data.teams,
		teamPersons: data.teamPersons,
		schedulingOptions,
	});

	tracking.trackEvent('Open Allocation Modal from the context Menu');
	trackComplexEvent('Allocation Modal', 'Opened', {location: 'From Context Menu'});
};

const duplicateAllocation = (pageComponent, allocation) => {
	const {schedulingView} = pageComponent.props;

	if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS && pageComponent.state.staffingModeActive) {
		const newAllocation = {
			...allocation,
			id: generateStaffingId(),
			parentId: allocation.parentId || allocation.id,
		};

		const res = {
			duplicateAllocation: {
				allocation: {
					node: {
						...newAllocation,
					},
				},
			},
		};

		onSuccess(pageComponent, res);
	} else {
		const {
			personId,
			startYear,
			startMonth,
			startDay,
			endYear,
			endMonth,
			endDay,
			idleTimeId,
			projectId,
			projectGroupId,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			description,
			registerTime,
			isSoft,
		} = allocation;

		const newAllocationData = {
			personId,
			startYear,
			startMonth,
			startDay,
			endYear,
			endMonth,
			endDay,
			idleTimeId,
			projectId,
			projectGroupId,
			monday,
			tuesday,
			wednesday,
			thursday,
			friday,
			saturday,
			sunday,
			description,
			registerTime,
			isSoft,
		};

		createAllocation(newAllocationData, res => onSuccess(pageComponent, res));

		tracking.trackEvent('Duplicate Allocation from the context Menu');
		trackComplexEvent('Allocation', 'Duplicated', {location: 'From Context Menu'});
	}
};

const onDeleteAllocation = (pageComponent, allocation) => {
	const {schedulingView} = pageComponent.props;

	if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS && pageComponent.state.staffingModeActive) {
		const res = {
			deleteAllocation: allocation,
		};

		onSuccess(pageComponent, res);
	} else {
		deleteAllocation(
			{
				id: allocation.id,
			},
			res => onSuccess(pageComponent, res)
		);

		tracking.trackEvent('Delete Allocation from the context Menu');
		trackComplexEvent('Allocation', 'Deleted', {location: 'From Context Menu'});
	}
};

export const onAllocationContextMenu = (pageComponent, e, item, canvasSplitDate) => {
	pageComponent.setState({showDetailBox: false, hoverX: null, hoverY: null});
	const contextMenuOptions = [];
	const data = pageComponent.getData();
	const {
		allocation,
		allocation: {projectId, projectGroupId, idleTimeId, personId},
	} = item.data;
	const {
		viewer: {actualPersonId},
		projectPersons,
	} = data;

	let isDisabledIdleTime = false;

	if (idleTimeId) {
		isDisabledIdleTime = data.idleTimes.find(idleTime => idleTime.id === idleTimeId).disabled;
	}

	if (projectId) {
		const project = data.projects.find(project => project.id === projectId);
		if (isProjectDoneOrHalted(project.status)) return;
	}

	// a person has access to the context menu only if he is assigned to the project or is the person allocated (this applies to idle times) or is an admin
	const hasAccessToProject =
		projectPersons.some(
			pp =>
				pp.personId === actualPersonId &&
				(pp.projectId === projectId ||
					data.projects.find(project => project.id === pp.projectId && project.projectGroupId === projectGroupId))
		) ||
		personId === actualPersonId ||
		hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL);

	// an allocation smaller than a day cannot be splitDate. if the split date is the same as start date, add a day so that the new allocation lasts one day
	let splitDate =
		item.startDate === item.endDate ? null : item.startDate === canvasSplitDate ? canvasSplitDate + 1 : canvasSplitDate;

	if (!allocation.bambooHRLinked) {
		const allowEdit = allowAllocationEdit(allocation);
		const allowModifyOwnTimeOff =
			isTimeOffAllocation(allocation) &&
			data.company.allUsersModifyTimeOff &&
			hasFeatureFlag('pto_all_users_can_modify_time_off');

		if (
			(hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) && allowEdit && splitDate && !isDisabledIdleTime) ||
			allowModifyOwnTimeOff
		) {
			contextMenuOptions.push({
				text: pageComponent.props.intl.formatMessage({id: 'common.split'}),
				onClick: () => {
					pageComponent.setState({
						splitAllocationBarData: {
							...pageComponent.state.splitAllocationBarData,
							visible: false,
						},
					});
					splitAllocation(pageComponent, allocation, splitDate);
				},
				onMouseEnter: () => {
					pageComponent.setState({
						splitAllocationBarData: {
							...pageComponent.state.splitAllocationBarData,
							visible: true,
						},
					});
				},
				onMouseLeave: () => {
					pageComponent.setState({
						splitAllocationBarData: {
							...pageComponent.state.splitAllocationBarData,
							visible: false,
						},
					});
				},
			});
		}

		contextMenuOptions.push({
			text: pageComponent.props.intl.formatMessage({
				id:
					(hasPermission(PERMISSION_TYPE.ALLOCATION_UPDATE) && allowEdit && !isDisabledIdleTime) ||
					(isTimeOffAllocation(allocation) &&
						data.company.allUsersModifyTimeOff &&
						hasFeatureFlag('pto_all_users_can_modify_time_off'))
						? 'common.edit'
						: 'common.view',
			}),
			onClick: () => editAllocation(pageComponent, allocation),
		});

		if (
			(hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) && allowEdit && !isDisabledIdleTime) ||
			(isTimeOffAllocation(allocation) &&
				data.company.allUsersModifyTimeOff &&
				hasFeatureFlag('pto_all_users_can_modify_time_off'))
		) {
			contextMenuOptions.push({
				text: pageComponent.props.intl.formatMessage({id: 'common.duplicate'}),
				onClick: () => duplicateAllocation(pageComponent, allocation),
			});
		}

		if (
			(hasPermission(PERMISSION_TYPE.ALLOCATION_DELETE) && allowEdit) ||
			(isTimeOffAllocation(allocation) &&
				data.company.allUsersModifyTimeOff &&
				hasFeatureFlag('pto_all_users_can_modify_time_off'))
		) {
			contextMenuOptions.push({
				text: pageComponent.props.intl.formatMessage({id: 'common.delete'}),
				onClick: () => onDeleteAllocation(pageComponent, allocation),
			});
		}
	}

	if (hasAccessToProject && !item.updateLock && !item.data.isDisabled) {
		pageComponent.setState({
			contextMenuX: e.clientX + 5,
			contextMenuY: e.clientY,
			contextMenuOptions,
			splitAllocationBarData: {canvasDate: splitDate, item, visible: false},
		});
	}
};

// PLACEHOLDER ALLOCATION CONTEXT MENU

const splitPlaceholderAllocation = (pageComponent, placeholderAllocation, canvasDate) => {
	const {staffingModeActive} = pageComponent.state;
	const splitDate = getMomentFromCanvasTimelineDate(canvasDate);
	const endDateOfFirstPlaceholderAllocation = splitDate.clone().subtract(1, 'day').format(DATE_FORMAT_DAY);
	const startDateOfSecondPlaceholderAllocation = splitDate.format(DATE_FORMAT_DAY);

	if (staffingModeActive) {
		const updatedPlaceholderAllocation = {
			...placeholderAllocation,
			endDate: endDateOfFirstPlaceholderAllocation,
		};

		const newPlaceholderAllocation = {
			...placeholderAllocation,
			startDate: startDateOfSecondPlaceholderAllocation,
			id: generateStaffingId(),
			parentId: placeholderAllocation.parentId || placeholderAllocation.id,
		};

		const res = {
			splitPlaceholderAllocation: {
				placeholderAllocations: [updatedPlaceholderAllocation, newPlaceholderAllocation],
			},
		};

		onSuccess(pageComponent, res);
	} else {
		splitPlaceholderAllocationMutation(
			{
				placeholderAllocationId: placeholderAllocation.id,
				splitDateEnd: endDateOfFirstPlaceholderAllocation,
				splitDateStart: startDateOfSecondPlaceholderAllocation,
			},
			res => {
				tracking.trackEvent('Placeholder allocation was split from the context menu');
				trackComplexEvent('Placeholder Allocation', 'Split', {location: 'From Context Menu'});
				onSuccess(pageComponent, res);
			}
		);
	}
};

const duplicatePlaceholderAllocation = (pageComponent, placeholderAllocation, isProjectsScheduling) => {
	const {formatMessage} = pageComponent.props.intl;
	const {staffingModeActive} = pageComponent.state;

	if (staffingModeActive) {
		const newPlaceholderAllocation = {
			...placeholderAllocation,
			id: generateStaffingId(),
			parentId: placeholderAllocation.parentId || placeholderAllocation.id,
		};

		const res = {
			duplicatePlaceholderAllocation: {
				placeholderAllocation: {
					node: {
						...newPlaceholderAllocation,
					},
				},
			},
		};

		onSuccess(pageComponent, res, isProjectsScheduling);
	} else {
		duplicatePlaceholderAllocationMutation(
			{
				id: placeholderAllocation.id,
			},
			res => {
				onSuccess(pageComponent, res, isProjectsScheduling);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'placeholder.allocation_duplicated'}),
				});
			}
		);
	}
};

const dividePlaceholderAllocation = (pageComponent, placeholderAllocation, placeholder) => {
	const {staffingModeActive} = pageComponent.state;
	placeholderAllocation.placeholder = {id: placeholderAllocation.placeholderId};
	showModal({
		type: MODAL_TYPE.PLACEHOLDER_DIVIDE_ALLOCATION,
		staffingModeActive,
		placeholderInput: placeholder,
		placeholderAllocationInput: placeholderAllocation,
	});

	tracking.trackEvent('Open Allocation Modal from the context Menu');
	trackComplexEvent('Allocation Modal', 'Opened', {location: 'From Context Menu'});
};

const onDeletePlaceholderAllocation = (pageComponent, placeholderAllocation) => {
	const {formatMessage} = pageComponent.props.intl;
	const {staffingModeActive} = pageComponent.state;

	if (staffingModeActive) {
		const res = {
			deletePlaceholderAllocation: {
				placeholderAllocation,
			},
		};

		onSuccess(pageComponent, res);
	} else {
		deletePlaceholderAllocation(
			{
				id: placeholderAllocation.id,
			},
			res => {
				onSuccess(pageComponent, res);
				createToast({
					duration: 5000,
					message: formatMessage({id: 'placeholder.allocation_deleted'}),
				});
			}
		);

		tracking.trackEvent('Placeholder allocation deleted from context menu');
		trackComplexEvent('Placeholder Allocation', 'Deleted', {location: 'From Context Menu'});
	}
};

const editPlaceholderAllocation = (pageComponent, placeholderAllocation, placeholder) => {
	showModal({
		type: MODAL_TYPE.PLACEHOLDER_ALLOCATION,
		projectId: placeholder.projectId,
		projectGroupId: placeholder.projectGroupId,
		staffingModeActive: pageComponent.state.staffingModeActive,
		placeholderInput: placeholder,
		placeholderAllocationInput: placeholderAllocation,
	});

	tracking.trackEvent('Open Allocation Modal from the context Menu');
	trackComplexEvent('Allocation Modal', 'Opened', {location: 'From Context Menu'});
};

export const onPlaceholderAllocationContextMenu = (pageComponent, e, item, canvasSplitDate, staffed) => {
	pageComponent.setState({showDetailBox: false, hoverX: null, hoverY: null});

	const contextMenuOptions = [];
	const data = pageComponent.getData();

	const {
		viewer: {actualPersonId},
		projectPersons,
		placeholders,
	} = data;

	const {placeholderAllocation} = item.data;
	const placeholder = placeholders.find(placeholder => placeholder.id === placeholderAllocation.placeholderId);
	const {projectId, projectGroupId} = placeholder;

	if (projectId) {
		const project = data.projects.find(project => project.id === projectId);
		if (isProjectDoneOrHalted(project.status)) return;
	}

	// a person has access to the context menu only if he is assigned to the project or is an admin
	const hasAccessToProject =
		projectPersons.some(
			pp =>
				pp.personId === actualPersonId &&
				(pp.projectId === projectId ||
					data.projects.find(project => project.id === pp.projectId && project.projectGroupId === projectGroupId))
		) || hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL);

	if (hasFeatureFlag('placeholders_beta_changes') || hasFeatureFlag('capacity_planning_beta_2_improvements')) {
		// ASSIGN TO PERSON
		if (!staffed && canApproveAllocation()) {
			const {staffingModeActive} = pageComponent.state;
			const data = pageComponent.getData();
			let suggestedPersonIds = undefined;
			if (staffingModeActive) {
				suggestedPersonIds = data.suggestedPersons.map(personRank => personRank.personId);
			}
			contextMenuOptions.push({
				text: pageComponent.props.intl.formatMessage({
					id: hasFeatureFlag('capacity_planning_beta_2_improvements')
						? 'placeholder.assign_to_team_member.action'
						: 'placeholder.assign_to_person.action',
				}),
				onClick: () => {
					trackEvent('Assign To From Placeholder Allocation Context Menu', 'Clicked');
					handleAssignToPerson(pageComponent, placeholder, [placeholderAllocation.id], suggestedPersonIds);
				},
			});
		}
	} else {
		// TRANSFER TO PERSON
		if (!staffed && canApproveAllocation()) {
			contextMenuOptions.push({
				text: pageComponent.props.intl.formatMessage({id: 'placeholder.transfer_to_person.action'}),
				onClick: () => handleTransferToPerson(pageComponent, placeholder, placeholderAllocation),
			});
		}
	}

	// DIVIDE
	contextMenuOptions.push({
		text: pageComponent.props.intl.formatMessage({id: 'scheduling.divide_allocation'}),
		onClick: () => dividePlaceholderAllocation(pageComponent, placeholderAllocation, placeholder),
	});

	// an allocation smaller than a day cannot be split.
	// If the split date is the same as start date, add a day so that the new allocation lasts one day
	const splitDate =
		item.startDate === item.endDate ? null : item.startDate === canvasSplitDate ? canvasSplitDate + 1 : canvasSplitDate;

	if (splitDate && !staffed) {
		contextMenuOptions.push({
			text: pageComponent.props.intl.formatMessage(
				hasFeatureFlag('placeholders_beta_changes')
					? {id: 'placeholder.placeholder_allocation_split'}
					: {id: 'common.split'}
			),
			onClick: () => {
				pageComponent.setState({
					splitPlaceholderAllocationBarData: {
						...pageComponent.state.splitPlaceholderAllocationBarData,
						visible: false,
					},
				});
				splitPlaceholderAllocation(pageComponent, placeholderAllocation, splitDate);
			},
			onMouseEnter: () => {
				pageComponent.setState({
					splitPlaceholderAllocationBarData: {
						...pageComponent.state.splitPlaceholderAllocationBarData,
						visible: true,
					},
				});
			},
			onMouseLeave: () => {
				pageComponent.setState({
					splitPlaceholderAllocationBarData: {
						...pageComponent.state.splitPlaceholderAllocationBarData,
						visible: false,
					},
				});
			},
		});
	}

	if (!hasFeatureFlag('placeholders_beta_changes')) {
		contextMenuOptions.push({
			text: pageComponent.props.intl.formatMessage({id: 'common.edit'}),
			onClick: () => editPlaceholderAllocation(pageComponent, placeholderAllocation, placeholder),
		});
	}

	contextMenuOptions.push({
		text: pageComponent.props.intl.formatMessage(
			hasFeatureFlag('placeholders_beta_changes')
				? {id: 'placeholder.placeholder_allocation_duplicate'}
				: {id: 'common.duplicate'}
		),
		onClick: () => duplicatePlaceholderAllocation(pageComponent, placeholderAllocation),
	});

	contextMenuOptions.push({
		text: pageComponent.props.intl.formatMessage(
			hasFeatureFlag('placeholders_beta_changes')
				? {id: 'placeholder.placeholder_allocation_delete'}
				: {id: 'common.delete'}
		),
		onClick: () => onDeletePlaceholderAllocation(pageComponent, placeholderAllocation),
	});

	if (hasAccessToProject && hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) && !item.isGhost) {
		pageComponent.setState({
			contextMenuX: e.clientX + 5,
			contextMenuY: e.clientY,
			contextMenuOptions,
			splitPlaceholderAllocationBarData: {canvasDate: splitDate, item, visible: false},
		});
	}
};

// TASK CONTEXT MENU

const onBugOptionClick = (pageComponent, task) => {
	updateTask(
		{
			ids: [task.id],
			bug: !task.bug,
		},
		res => onSuccess(pageComponent, res)
	);
};

const onPriorityOptionClick = (pageComponent, task) => {
	updateTask(
		{
			ids: [task.id],
			highPriority: !task.highPriority,
		},
		res => onSuccess(pageComponent, res)
	);
};

const onDuplicateOptionClick = (pageComponent, task) => {
	duplicateTask(
		{
			taskId: task.id,
		},
		res => onSuccess(pageComponent, res)
	);
};
const onMoveToProjectClick = task => {
	showModal({
		type: MODAL_TYPE.TASK_LOCATION,
		taskId: task.id,
	});
};

const onBlockOptionClick = (pageComponent, task) => {
	updateTask(
		{
			ids: [task.id],
			blocked: !task.blocked,
		},
		res => onSuccess(pageComponent, res)
	);
};

const onDeleteOptionClick = (pageComponent, task) => {
	const {viewer} = pageComponent.getData();
	const hasChildren = DataManager.getSubTasksByParentTaskId(pageComponent, task.id)?.length > 0;

	showModal({
		type: MODAL_TYPE.TASK_DELETION_WARNING,
		task: {
			...task,
			hasChildren,
		},
		deleteCallback: () => {
			Util.CommitSchedulingModalUpdate(DeleteTaskMutation, {
				ids: [task.id],
				projectId: task.projectId,
				viewer: viewer,
			});
		},
	});
};

const validateCanDeleteTaskBeforeAction = (pageComponent, task, callback) => {
	const onValidationSuccess = response => {
		const {userCanDeleteTask, userCantDeleteTaskReason} = response?.validateCanUserDeleteTask;

		if (userCanDeleteTask) {
			callback();
		} else {
			const {intl} = pageComponent.props;
			const {formatMessage} = intl;
			createToast({
				duration: 5000,
				message: formatMessage(
					{id: 'common.action_not_allowed'},
					{reason: getCantDeleteTaskMessage(intl, userCantDeleteTaskReason)}
				),
			});
		}
	};

	Util.CommitMutation(
		ValidateCanUserDeleteTaskMutation,
		{
			taskId: task.id,
		},
		response => onValidationSuccess(response)
	);
};

// OBS: This function only works in PROJECT SCHEDULING
export const onTaskContextMenu = (pageComponent, e, item) => {
	const {schedulingView, intl, isProjectTimeline, isMySchedule} = pageComponent.props;
	const {formatMessage} = intl;
	const {data} = pageComponent.state;
	const {
		viewer: {actualPersonId},
		projectPersons,
	} = data;

	const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

	let contextMenuOptions = [];

	let task;
	if (isProjectScheduling) {
		task = data.tasks.find(task => task.id === item.groupId);
	} else {
		task = data.tasks.find(task => task.id === item.data.task.id);
	}

	//Do not allow fixing dependencies for readonly tasks
	if (!task || task.readOnly?.isReadOnly) return;

	//MARK AS BUG OPTION
	contextMenuOptions.push({
		text: formatMessage({id: task.bug ? 'card_modal.unmark-as-bug' : 'card_modal.mark-as-bug'}),
		onClick: () => onBugOptionClick(pageComponent, task),
	});

	// MARK AS HIGH PRIO OPTION
	contextMenuOptions.push({
		text: formatMessage({id: task.highPriority ? 'task-modal.unmark-priority' : 'task-modal.mark-priority'}),
		onClick: () => onPriorityOptionClick(pageComponent, task),
	});

	const subTaskCount = DataManager.getSubTasksByParentTaskId(pageComponent, task.id)?.length;
	const isSubTaskOrTaskWithSubTasks = !!task.parentTaskId || subTaskCount > 0;

	// DUPLICATE OPTION
	contextMenuOptions.push({
		text: formatMessage({id: 'common.duplicate'}),
		onClick: () => onDuplicateOptionClick(pageComponent, task),
	});

	// MOVE TO PROJECT OPTION
	if (hasFeatureFlag('schedule_context_menu_fixes')) {
		contextMenuOptions.push({
			text: formatMessage({id: 'task_location_modal.title'}),
			onClick: () => {
				if (isSubTaskOrTaskWithSubTasks) {
					createToast({
						duration: 5000,
						message: formatMessage(
							{id: 'common.action_not_allowed'},
							{reason: formatMessage({id: 'task.cant_move_project_reason.is_subtask_or_task_with_subtasks'})}
						),
					});
				} else {
					validateCanDeleteTaskBeforeAction(pageComponent, task, () => onMoveToProjectClick(task));
				}
			},
		});
	} else {
		const userCanDelete = !isClientUser();
		let moveDisabledText = userCanDelete
			? formatMessage({id: 'scheduling.task_move_locked'})
			: formatMessage({id: 'common.no_move_rights'});
		moveDisabledText = isSubTaskOrTaskWithSubTasks
			? formatMessage({id: 'scheduling.task_move_locked_sub_task'})
			: moveDisabledText;

		contextMenuOptions.push({
			text: formatMessage({id: 'task_location_modal.title'}),
			onClick: () => validateCanDeleteTaskBeforeAction(pageComponent, task, () => onMoveToProjectClick(task)),
			disabled: !task.userCanDeleteTask || isSubTaskOrTaskWithSubTasks,
			disabledDescription: moveDisabledText,
		});
	}

	if (isProjectScheduling) {
		// DEPENDENCIES OPTIONS
		const dependencyChainTaskIdSet = new Set();
		dependencyChainTaskIdSet.add(item.groupId);

		let previousTaskIdCount = 0;
		let currentTaskIdCount = 1;
		while (previousTaskIdCount !== currentTaskIdCount) {
			for (const dependency of pageComponent.state.dependencies.filter(
				dependency =>
					dependencyChainTaskIdSet.has(dependency.thisDependsOnTaskId) ||
					dependencyChainTaskIdSet.has(dependency.taskIdDependsOnThis)
			)) {
				dependencyChainTaskIdSet.add(dependency.thisDependsOnTaskId);
				dependencyChainTaskIdSet.add(dependency.taskIdDependsOnThis);
			}
			previousTaskIdCount = currentTaskIdCount;
			currentTaskIdCount = dependencyChainTaskIdSet.size;
		}

		const relevantTaskMap = new Map();
		for (const taskId of dependencyChainTaskIdSet.values()) {
			const taskItem = pageComponent.state.items.find(item => item.groupId === taskId);
			if (!taskItem) continue;
			relevantTaskMap.set(taskId, taskItem);
		}

		const getQuestionableDependencies = () => {
			const relevantDependencies = pageComponent.state.dependencies.filter(
				dependency =>
					dependencyChainTaskIdSet.has(dependency.thisDependsOnTaskId) ||
					dependencyChainTaskIdSet.has(dependency.taskIdDependsOnThis)
			);
			const questionableDependencies = [];
			for (const dependency of relevantDependencies) {
				const laterTask = relevantTaskMap.get(dependency.taskIdDependsOnThis);
				const earlierTask = relevantTaskMap.get(dependency.thisDependsOnTaskId);
				if (!laterTask || !earlierTask) continue;
				if (dependency.type === DependencyType.CANNOT_START) {
					if (laterTask.startDate < earlierTask.endDate + 1) {
						questionableDependencies.push(dependency);
					}
				} else {
					if (laterTask.endDate < earlierTask.endDate) {
						questionableDependencies.push(dependency);
					}
				}
			}
			return questionableDependencies;
		};

		let questionableDependencies = getQuestionableDependencies();
		if (questionableDependencies.length) {
			contextMenuOptions.push({
				text: formatMessage({id: 'scheduling.fix_dependency_chain'}),
				onClick: () => {
					const taskToUpdateSet = new Set();
					const companyData = pageComponent.state.data.company;
					const companyWorkingDays = new Map([
						[1, companyData.monday],
						[2, companyData.tuesday],
						[3, companyData.wednesday],
						[4, companyData.thursday],
						[5, companyData.friday],
						[6, companyData.saturday],
						[7, companyData.sunday],
					]);
					// check if company has any working day to avoid infinite loops in the while statements
					const companyHasWorkingDay = [
						companyData.monday,
						companyData.tuesday,
						companyData.wednesday,
						companyData.thursday,
						companyData.friday,
						companyData.saturday,
						companyData.sunday,
					].find(el => el !== 0);

					while (questionableDependencies.length) {
						for (const dependency of questionableDependencies) {
							const laterTask = relevantTaskMap.get(dependency.taskIdDependsOnThis);
							const earlierTask = relevantTaskMap.get(dependency.thisDependsOnTaskId);
							if (!laterTask || !earlierTask) continue;
							const laterTaskLength = laterTask.endDate - laterTask.startDate;
							if (dependency.type === DependencyType.CANNOT_START) {
								const findNextWorkingDay = newDate => {
									let i = 1;
									let toNextWorkingDay = 0;
									while (toNextWorkingDay === 0) {
										const nextDayKey = getMomentFromCanvasTimelineDate(newDate + i).isoWeekday();
										const nextDayWorkingHours = companyWorkingDays.get(nextDayKey);
										if (nextDayWorkingHours !== 0) {
											toNextWorkingDay = i;
										}
										i++;
									}
									return toNextWorkingDay;
								};
								let newStartDate = earlierTask.endDate + 1;
								const newStartDateKey = getMomentFromCanvasTimelineDate(newStartDate).isoWeekday();
								const toNextWorkingDayStart =
									companyWorkingDays.get(newStartDateKey) === 0 && companyHasWorkingDay !== undefined
										? findNextWorkingDay(newStartDate)
										: 0;
								newStartDate += toNextWorkingDayStart;

								let newEndDate = newStartDate + laterTaskLength;
								const newEndDateKey = getMomentFromCanvasTimelineDate(newEndDate).isoWeekday();
								const toNextWorkingDayEnd =
									companyWorkingDays.get(newEndDateKey) === 0 && companyHasWorkingDay !== undefined
										? findNextWorkingDay(newEndDate)
										: 0;
								newEndDate += toNextWorkingDayEnd;

								const oldWorkingDaysCount = Util.getWorkingDaysDuringAllocation(
									getMomentFromCanvasTimelineDate(laterTask.startDate),
									getMomentFromCanvasTimelineDate(laterTask.endDate),
									companyData.monday,
									companyData.tuesday,
									companyData.wednesday,
									companyData.thursday,
									companyData.friday,
									companyData.saturday,
									companyData.sunday
								);
								const newWorkingDaysCount = Util.getWorkingDaysDuringAllocation(
									getMomentFromCanvasTimelineDate(newStartDate),
									getMomentFromCanvasTimelineDate(newEndDate),
									companyData.monday,
									companyData.tuesday,
									companyData.wednesday,
									companyData.thursday,
									companyData.friday,
									companyData.saturday,
									companyData.sunday
								);
								if (companyHasWorkingDay !== undefined && oldWorkingDaysCount > newWorkingDaysCount) {
									let daysToAdd = 0;
									let workingDaysAdded = 0;
									while (workingDaysAdded < oldWorkingDaysCount - newWorkingDaysCount) {
										daysToAdd++;
										const nextDaykey = getMomentFromCanvasTimelineDate(newEndDate + daysToAdd).isoWeekday();
										const nextDayWorkingHours = companyWorkingDays.get(nextDaykey);
										if (nextDayWorkingHours !== 0) {
											workingDaysAdded++;
										}
									}
									newEndDate += daysToAdd;
								}
								laterTask.startDate = newStartDate;
								laterTask.endDate = newEndDate;
							} else {
								laterTask.endDate = earlierTask.endDate;
								laterTask.startDate = laterTask.endDate - laterTaskLength;
							}
							taskToUpdateSet.add(laterTask);
						}
						questionableDependencies = getQuestionableDependencies();
					}
					for (const taskToUpdate of taskToUpdateSet.values()) {
						taskToUpdate.updateTaskDates();
					}
					dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: true});
					pageComponent.setState({
						contextMenuX: undefined,
						contextMenuY: undefined,
						contextMenuOptions: undefined,
					});
				},
			});
		}

		const getDependeciesTaskIdList = () => {
			const taskId = item.data.task.id;
			let dependenciesList = [];
			for (let dependency of pageComponent.state.dependencies) {
				if (dependency.taskIdDependsOnThis === taskId) {
					dependenciesList.push({
						dependencyTaskId: dependency.thisDependsOnTaskId,
						dependencyId: dependency.id,
						hasTemporaryId: dependency.hasTemporaryId,
					});
				} else if (dependency.thisDependsOnTaskId === taskId) {
					dependenciesList.push({
						dependencyTaskId: dependency.taskIdDependsOnThis,
						dependencyId: dependency.id,
						hasTemporaryId: dependency.hasTemporaryId,
					});
				}
			}

			return dependenciesList;
		};

		// if the clicked task has dependencies, add the options to remove them in the dropdown
		let dependenciesList = getDependeciesTaskIdList();
		if (dependenciesList.length && !pageComponent.state.simulationMode) {
			for (let dependency of dependenciesList) {
				// get the id of the dependency task
				let dependencyTask = data.tasks.find(task => dependency.dependencyTaskId === task.id);

				if (dependencyTask) {
					let dependencyTaskCompanyId = dependencyTask.companyTaskId;
					// add option in the menu
					contextMenuOptions.push({
						text: formatMessage({id: 'scheduling.delete_dependency_to'}, {taskId: `T${dependencyTaskCompanyId}`}),
						trackingName: 'Delete Dependency To',
						onClick: () => onDependencyDeleted(pageComponent, dependency.dependencyId),
						disabled: dependency.hasTemporaryId,
					});
				}
			}
		}
	}

	// BLOCK OPTION
	contextMenuOptions.push({
		text: formatMessage({id: task.blocked ? 'card_modal.unblock-card' : 'card_modal.block-card'}),
		onClick: () => onBlockOptionClick(pageComponent, task),
	});

	// DELETE OPTION
	if (hasFeatureFlag('schedule_context_menu_fixes')) {
		contextMenuOptions.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: () =>
				validateCanDeleteTaskBeforeAction(pageComponent, task, () => onDeleteOptionClick(pageComponent, task)),
		});
	} else {
		contextMenuOptions.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: () =>
				validateCanDeleteTaskBeforeAction(pageComponent, task, () => onDeleteOptionClick(pageComponent, task)),
			disabled: !task.userCanDeleteTask,
			disabledDescription: getCantDeleteTaskMessage(intl, task.userCantDeleteTaskReason),
		});
	}

	// a person has access to the context menu only if he is assigned to the project or is the person allocated (this applies to idle times) or is an admin
	const hasAccessToContextMenu =
		(projectPersons.some(pp => pp.personId === actualPersonId && pp.projectId === task.projectId) ||
			hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) &&
		!pageComponent.state.initData?.projectSchedulingShare;

	const taskIsDisabled =
		hasFeatureFlag('scheduling_read_only_permissions') &&
		!isMySchedule &&
		!isProjectTimeline &&
		!hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS);
	if (hasAccessToContextMenu && !taskIsDisabled)
		pageComponent.setState({
			contextMenuX: e.clientX,
			contextMenuY: e.clientY,
			contextMenuOptions,
		});
};
