import React, { useMemo, useState } from 'react';
import { FlexColumn, Heading, Link, Modal, SubHeading, Text, Checkbox } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
import { createToast } from '../../forecast-app/shared/components/toasts/toast';
import UpdateProfileMutation from '../../mutations/UpdateProfileMutation';
import { PERMISSION_TYPE } from '../../Permissions';
import { hasModule } from '../../forecast-app/shared/util/ModuleUtil';
import { MODULE_TYPES } from '../../constants';
import { CLIENT_PERMISSIONS_WITH_GUEST_USER } from './ClientPermissionGroups';
const HELP_CENTER_URL = 'https://support.forecast.app/hc/en-us/articles/5446751642385-Adding-and-managing-Guest-Users';
const ClientPermissionsModal = ({ closeModal, profile }) => {
    const { formatMessage } = useIntl();
    const [permissions, setPermissions] = useState(new Set([...profile.permissions]));
    const hasSisenseModule = useMemo(() => hasModule(MODULE_TYPES.SISENSE), []);
    const permissionGroups = useMemo(() => {
        return CLIENT_PERMISSIONS_WITH_GUEST_USER.filter(group => {
            if (group.permissions.includes(PERMISSION_TYPE.SISENSE_READ)) {
                // Only show sisense groups for people with sisense module
                return hasSisenseModule;
            }
            return true;
        });
    }, []);
    const helpCenterLink = (React.createElement(Link, { href: HELP_CENTER_URL },
        React.createElement(FormattedMessage, { id: 'common.help_center_article' })));
    const onSave = () => {
        const onSuccess = () => {
            createToast({
                duration: 5000,
                message: formatMessage({ id: 'settings.profile_updated' }),
            });
            closeModal();
        };
        const input = {
            id: profile.id,
            name: profile.name,
            profilePermissions: Array.from(permissions),
        };
        Util.CommitMutation(UpdateProfileMutation, input, onSuccess);
    };
    const isChecked = (permissionGroup) => {
        let isChecked = true;
        for (const permission of permissionGroup.permissions) {
            if (!permissions.has(permission)) {
                isChecked = false;
                break;
            }
        }
        return isChecked;
    };
    const onPermissionGroupSelect = (permissionGroup) => {
        const isSelected = isChecked(permissionGroup);
        for (const permission of permissionGroup.permissions) {
            if (isSelected) {
                permissions.delete(permission);
            }
            else {
                permissions.add(permission);
            }
        }
        setPermissions(new Set(permissions));
    };
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(Heading, { size: 3 },
                React.createElement(FormattedMessage, { id: 'settings.edit_client_permissions' }))),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: 'settings.edit_client_permissions_description' })),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { id: 'settings.edit_client_permissions_help_center_article', values: { link: helpCenterLink } })),
            React.createElement(FlexColumn, null,
                React.createElement(SubHeading, null,
                    React.createElement(FormattedMessage, { id: "common.permissions" })),
                React.createElement(Text, { color: 'medium' },
                    React.createElement(FormattedMessage, { id: 'settings.modify_client_permission_options' }))),
            React.createElement(FlexColumn, { gap: 'xl', alignItems: 'start' }, permissionGroups.map(permissionGroup => (React.createElement(Checkbox, { checked: isChecked(permissionGroup), onChange: () => onPermissionGroupSelect(permissionGroup), labelText: permissionGroup.name, description: permissionGroup.description }))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onSave },
                React.createElement(FormattedMessage, { id: 'common.save' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
};
export default ClientPermissionsModal;
