import React, { useMemo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Alert, FlexColumn, FlexRow, Frame, Modal, Text } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import ProjectIndicator from '../../../forecast-app/shared/components/project-indicator/ProjectIndicator';
const InvoicedBillToWarningModal = ({ billToId, clientId, closeModal, viewer }) => {
    const { formatMessage } = useIntl();
    const projects = useMemo(() => {
        var _a, _b, _c;
        const filteredInvoices = (_c = (_b = (_a = viewer.invoicesV2) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(invoice => invoice === null || invoice === void 0 ? void 0 : invoice.node)) === null || _c === void 0 ? void 0 : _c.filter(invoice => { var _a, _b; return ((_a = invoice === null || invoice === void 0 ? void 0 : invoice.billTo) === null || _a === void 0 ? void 0 : _a.id) === billToId && (!clientId || ((_b = invoice === null || invoice === void 0 ? void 0 : invoice.client) === null || _b === void 0 ? void 0 : _b.id) === clientId); });
        const allProjects = filteredInvoices === null || filteredInvoices === void 0 ? void 0 : filteredInvoices.flatMap(invoice => invoice === null || invoice === void 0 ? void 0 : invoice.projects);
        // Remove duplicates
        return allProjects === null || allProjects === void 0 ? void 0 : allProjects.filter((project, index) => {
            return (allProjects === null || allProjects === void 0 ? void 0 : allProjects.findIndex(x => (x === null || x === void 0 ? void 0 : x.id) === (project === null || project === void 0 ? void 0 : project.id))) === index;
        });
    }, [viewer.invoicesV2]);
    let modalTitle, modalAlert, modalDesc1, modalDesc2;
    if (clientId) {
        modalTitle = formatMessage({ id: 'settings_finance.bill_to.unassociate_client' });
        modalAlert = formatMessage({ id: 'settings_finance.bill_to.unassociate_client.invoiced.alert' });
        modalDesc1 = formatMessage({ id: 'settings_finance.bill_to.unassociate_client.invoiced.desc1' });
        modalDesc2 = formatMessage({ id: 'settings_finance.bill_to.unassociate_client.invoiced.desc2' });
    }
    else {
        modalTitle = formatMessage({ id: 'settings_finance.bill_to.remove' });
        modalAlert = formatMessage({ id: 'settings_finance.remove_bill_to_entity_alert' });
        modalDesc1 = formatMessage({ id: 'settings_finance.bill_to_entity_warning_desc_1' });
        modalDesc2 = formatMessage({ id: 'settings_finance.bill_to_entity_warning_desc_2' });
    }
    return (React.createElement(Modal, { onCloseModal: closeModal },
        React.createElement(Modal.Title, null, modalTitle),
        React.createElement(Modal.Content, null,
            React.createElement(Alert, { text: modalAlert, type: Alert.TYPE.ERROR }),
            React.createElement(Text, null, modalDesc1),
            React.createElement(Frame, null,
                React.createElement(FlexColumn, { gap: 'm' }, projects === null || projects === void 0 ? void 0 : projects.map(project => project ? (React.createElement(FlexRow, { gap: 'm' },
                    React.createElement(ProjectIndicator, { project: project }),
                    React.createElement(Text, { ellipsis: true }, project.name))) : null))),
            React.createElement(Text, null, modalDesc2)),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal }, formatMessage({ id: 'common.close' })))));
};
export const InvoicedBillToWarningModalQuery = graphql `
	query InvoicedBillToWarningModalQuery {
		viewer {
			actualPersonId
			component(name: "InvoicedBillToWarningModal")
			...InvoicedBillToWarningModal_viewer
		}
	}
`;
export default createFragmentContainer(InvoicedBillToWarningModal, {
    viewer: graphql `
		fragment InvoicedBillToWarningModal_viewer on Viewer {
			id
			invoicesV2(first: 100000) {
				edges {
					node {
						id
						billTo {
							id
						}
						client {
							id
						}
						projects {
							id
							name
							projectColor
							companyProjectId
							...ProjectIndicator_project
						}
					}
				}
			}
		}
	`,
});
