import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Util from '../../forecast-app/shared/util/util';
import {Draggable, Droppable} from '@forecasthq/react-virtualized-dnd';
import CreateTaskMutation from '../../mutations/create_task_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import InsightsEmptyState from '../../forecast-app/shared/components/empty-states/insights_empty_state';
import InputFieldV2 from './input_field';
import TooltipIcon from '../../forecast-app/shared/components/tooltips/tooltip_icon';
import BacklogCard from './backlog_card';
import Search from './search';
import {FILTER_SECTION, FILTER_TYPE} from '../../constants';
import ProjectDropdown from '../../forecast-app/shared/components/dropdowns/project-dropdown-a/project_dropdown';
import {getFiltersAlphabetically} from '../../forecast-app/shared/components/filters/filter_util';
import FilterV4, {FILTER_SECTIONS} from '../../forecast-app/shared/components/filters/FilterWrapper';
import {getFilterFunctions} from '../../forecast-app/shared/components/filters/filter_logic';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';

class SideBacklog extends Component {
	constructor(props) {
		super(props);

		const projectId = props.isConnectedParent ? props.viewer.projectGroup.id : props.viewer.project.id;
		let expanded = false;
		let filters;
		let filterKey = `project-sprintV3-backlog-filters-v4-${projectId}`;
		if (localStorage.getItem(filterKey)) {
			filters = JSON.parse(localStorage.getItem(filterKey));
		} else {
			filters = {
				person: {},
				task: {},
			};
		}
		const filterFunctions = getFilterFunctions(filters);
		this.state = {
			filters,
			filterFunctions,
			expanded: expanded,
			columnTop: 0,
			newTaskInputValue: '',
			phaseFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-phase')) || []
			).filter(val => val !== null),
			statusFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-status')) || []
			).filter(val => val !== null),
			labelFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-label')) || []
			).filter(val => val !== null),
			roleFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-role')) || []
			).filter(val => val !== null),
			blocked_bugFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-blocked_bug')) || []
			).filter(val => val !== null),
			recentlyUpdatedFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-recentlyUpdated')) || []
			).filter(val => val !== null),
			teamFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-team')) || []
			).filter(val => val !== null),
			teammemberFilterValue: (
				JSON.parse(localStorage.getItem('project-sprints-backlog-filter-value-' + projectId + '-teammember')) || []
			).filter(val => val !== null),
			searchFilterValue: '',
			searchExpanded: false,
			selectedProject: this.props.isConnectedParent
				? this.props.viewer.projectGroup.projects.edges.find(
						project => project.node.status !== 'HALTED' && project.node.status !== 'DONE'
				  )
				: null,
		};

		this.getFilter = this.getFilter.bind(this);
	}

	componentDidMount() {
		if (this._column_wrapper) {
			this.setState({
				columnTop: this._column_wrapper.getBoundingClientRect().top,
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.isDraggingSomething &&
			((nextProps.tasksBeingDragged.length !== 0 &&
				nextProps.tasksBeingDragged[0].node.phase &&
				nextProps.tasksBeingDragged[0].node.phase.id !== this.props.phase.id) ||
				(nextProps.tasksBeingDragged.length !== 0 &&
					!nextProps.tasksBeingDragged[0].node.phase &&
					this.props.phase.id) ||
				(nextProps.tasksBeingDragged.length !== 0 &&
					nextProps.tasksBeingDragged[0].node.phase &&
					!this.props.phase.id)) &&
			!this.props.phase.forceRender
		) {
			return false;
		}
		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.collapsed !== prevProps.collapsed) {
			this.setState({
				columnTop: this._column_wrapper.getBoundingClientRect().top,
			});
		}
		if (this.state.expanded !== prevState.expanded) {
			if (this.props.onExpansionChange) {
				this.props.onExpansionChange();
			}
		}
	}

	clearNewTask() {
		this.setState({createdTaskId: null});
	}

	handleToggleExpand() {
		this.setState(prevState => {
			Util.localStorageSetItem('backlog-expanded', !prevState.expanded);
			return {expanded: !prevState.expanded};
		});
	}

	handleNewTaskInputChange(value) {
		this.setState({newTaskInputValue: value});
	}

	handleAddTask() {
		if (this.props.isJiraProject || this.props.projectLocked) {
			return;
		}
		const onSuccess = res => {
			if (res.createTask.errors && res.createTask.errors.length === 1) {
				Util.checkForSageErrorAndShowModal(res.createTask.errors);
			} else {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'task_activity_log.CARD_CREATE',
					}),
					actionText: this.props.intl.formatMessage({id: 'common.see_task'}),
					actionCallback: () => Util.showTaskModal(res.createTask.task.node.companyTaskId, this.props.history),
				});
				this.setState({createdTaskId: res.createTask.task.node.companyTaskId});
				if (this._droppable) {
					setTimeout(() => this._droppable.scrollTop(this._droppable.getScrollHeight() + 100), 100);
				}
			}
		};
		const name = this.state.newTaskInputValue;
		const projectId = this.props.isConnectedParent ? this.state.selectedProject.node.id : this.props.projectId;
		if (projectId && name) {
			const mutationObject = {
				projectId: projectId,
				placementOfTask: 'BOTTOM',
				name: name,
			};
			Util.CommitMutation(CreateTaskMutation, mutationObject, onSuccess);
		}
		this.setState({
			newTaskInputValue: '',
		});
	}

	getIsRowSelected(rowId) {
		return !!this.props.selectedRows.find(row => (row.node ? row.node.id === rowId : row.id === rowId));
	}

	setSearchState(searchState) {
		this.setState({searchExpanded: searchState});
	}
	onSearchChange(searchFilterValue) {
		this.setState({searchFilterValue});
	}

	handleCollapsedClick() {
		if (!this.state.expanded) {
			this.setState({expanded: true});
		}
	}

	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value, selectedTasks: []});
		if (typeof value === 'string') {
			Util.localStorageSetItem(
				'project-sprints-backlog-filter-value-' +
					(!this.props.isConnectedParent ? this.props.viewer.project.id : this.props.viewer.projectGroup.id) +
					'-' +
					filterType,
				JSON.stringify(value)
			);
		} else {
			Util.localStorageSetItem(
				'project-sprints-backlog-filter-value-' +
					(!this.props.isConnectedParent ? this.props.viewer.project.id : this.props.viewer.projectGroup.id) +
					'-' +
					filterType,
				JSON.stringify(value.map(v => ({...v, label: null})))
			);
		}
	}
	//new filter component
	onFilterChange(filters, filterFunctions) {
		this.setState({filters, filterFunctions});
	}

	handleProjectSelect(project) {
		this.setState({selectedProject: project});
	}

	getFilter() {
		const taskFilters = [
			FILTER_TYPE.CLIENT_GUEST_USERS,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.INDICATOR,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.PROJECT_PHASE,
			FILTER_TYPE.PROJECT_PERSON,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.PROJECT_STATUS_COLUMN,
		];

		if (this.props.viewer.projectGroup) {
			taskFilters.push(FILTER_TYPE.PROJECT);
			taskFilters.push(FILTER_TYPE.PROJECT_STAGE);
		}

		if (this.props.viewer.project && this.props.viewer.project.useTaskFollowers) {
			taskFilters.push(FILTER_TYPE.PROJECT_FOLLOWER);
		}

		if (this.props.viewer.project && this.props.viewer.project.useTaskOwner) {
			taskFilters.push(FILTER_TYPE.PROJECT_OWNER);
		}

		if (this.props.viewer.project && this.props.viewer.project.taskLevels === 2) {
			taskFilters.push(FILTER_TYPE.SUB_TASKS);
		}

		if (this.props.viewer.company.teams.edges.length > 0) {
			taskFilters.push(FILTER_TYPE.TEAM);
		}

		const projectFilters = [];
		const peopleFilters = [];
		return (
			<FilterV4
				key={`filter-v4-component-sprint-backlog`}
				taskFilters={getFiltersAlphabetically(taskFilters, this.props.intl.formatMessage)}
				projectFilters={projectFilters}
				peopleFilters={peopleFilters}
				viewer={this.props.viewer}
				defaultSection={FILTER_SECTIONS.TASKS}
				projectId={this.props.viewer.project ? this.props.viewer.project.id : null}
				projectGroupId={this.props.viewer.projectGroup ? this.props.viewer.projectGroup.id : null}
				companyProjectGroupId={
					this.props.viewer.projectGroup ? this.props.viewer.projectGroup.companyProjectGroupId : null
				}
				companyProjectId={this.props.viewer.project ? this.props.viewer.project.companyProjectId : null}
				onFiltersChange={this.onFilterChange.bind(this)}
				appliedFiltersName={`project-sprintV3-backlog-filters-v4-${
					this.props.viewer.project ? this.props.viewer.project.id : this.props.viewer.projectGroup.id
				}`}
				filterSection={FILTER_SECTION.SPRINT_BACKLOG}
				iconOnly
			/>
		);
	}
	render() {
		const {formatMessage, formatNumber} = this.props.intl;
		const searchUsed = this.state.searchFilterValue.length;
		this.tasks = this.props.tasks;

		if (this.state.filterFunctions && this.state.filterFunctions.taskFilter) {
			const options = {
				isProjectGroup: this.props.viewer.projectGroup !== null && this.props.viewer.projectGroup !== undefined,
				teams: this.props.viewer.company.teams.edges,
			};
			this.tasks = this.tasks.filter(task => this.state.filterFunctions.taskFilter(task, options));
		}

		if (this.state.searchFilterValue.length > 0) {
			const searchFilter = task => {
				const taskValue = 'T' + task.companyTaskId + task.name;
				const searchValue = this.state.searchFilterValue.trim();
				return Util.normalizedIncludes(taskValue, searchValue);
			};
			this.tasks = this.tasks.filter(searchFilter);
		}
		const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		let maxContainerHeight = windowHeight - this.state.columnTop - 110 - (this.props.buyNowTime ? 40 : 0); // header + new task row is 176px
		// const sideBacklogColumns = this.props.availableColumns.filter(col => col.name === 'task-id' || col.name === 'task-name' || col.name === 'chip-right' || col.name === 'remaining');

		const taskHeight = 88;
		const tasksHeight = this.tasks.length * (taskHeight + 5);
		const riseAddTask = tasksHeight < maxContainerHeight;
		const addTaskStyle = {
			position: 'absolute',
			bottom: tasksHeight > 0 ? maxContainerHeight - tasksHeight + 25 : null,
			top: tasksHeight === 0 ? 45 : null,
			width: 100 + '%',
			paddingRight: 20,
		};
		//const estimationUnit = this.props.isEstimatedInHours ? formatMessage({id: 'common.hours.short'}) : formatMessage({id: 'common.points.short'});
		if (riseAddTask) {
			maxContainerHeight += 45;
		}
		const projectOptions = this.props.viewer.project ? [] : this.props.viewer.projectGroup.projects.edges;
		const noProject = this.props.viewer.project
			? this.props.viewer.project.status === 'HALTED' || this.props.viewer.project.status === 'DONE'
			: !projectOptions.find(p => p.node.status === 'RUNNING' || p.node.status === 'PLANNING');

		return (
			<div
				className={'side-backlog ' + (this.state.expanded ? 'expanded' : 'collapsed')}
				ref={this.props.innerRef}
				onClick={this.handleCollapsedClick.bind(this)}
				data-userpilot={'backlog-section'}
			>
				<header
					ref={div => (this._column_wrapper = div)}
					className={'header-container ' + (this.state.expanded ? '' : 'collapsed')}
					onClick={this.state.expanded ? null : this.handleToggleExpand.bind(this)}
				>
					{this.state.searchExpanded && this.state.expanded ? null : (
						<div className={'title'}>
							{formatMessage({id: 'project_sprints.backlog'})}
							<span className="tasks-number">
								{this.props.lazyDataFetched ? ' (' + formatNumber(this.tasks.length) + ')' : ' (-)'}
							</span>
						</div>
					)}
					<div className={'control ' + (this.state.expanded ? '' : 'dot-options')}>
						{this.state.expanded ? (
							<div className={'control-search-filter'}>
								<div className="control-search">
									<Search
										key={`backlog-search`}
										value={this.state.searchFilterValue}
										onChange={this.onSearchChange.bind(this)}
										placeholder={'Find in backlog'}
										setSearchState={this.setSearchState.bind(this)}
										alwaysExpanded={this.state.searchExpanded}
										startFocused={true}
										noSelectableProject={noProject}
									/>
								</div>
								{this.getFilter()}
							</div>
						) : null}
						<TooltipIcon
							infoText={
								this.state.expanded
									? formatMessage({id: 'workflow.collapse'})
									: formatMessage({id: 'workflow.expand'})
							}
							cy={this.props.cy + '-expand-control'}
							className={'expand-control ' + (this.state.expanded === true ? 'collapse' : 'expand')}
							onClick={this.state.expanded ? this.handleToggleExpand.bind(this) : null}
							userpilot={'backlog-expand-button'}
						/>
					</div>
				</header>
				{this.state.expanded ? (
					<div className={'body'} style={{minHeight: 50}}>
						{!this.props.lazyDataFetched ? (
							<div style={{height: Math.max(maxContainerHeight, 480)}}>
								<InlineLoader></InlineLoader>
							</div>
						) : this.tasks.length === 0 && searchUsed ? (
							<div style={{height: Math.max(maxContainerHeight, 480)}}>
								<InsightsEmptyState
									infoMessageId={searchUsed ? 'scheduling.no-match' : 'scheduling.no-match-generic'}
									searchText={this.state.searchFilterValue}
									small={true}
								/>
							</div>
						) : (
							<div className={'no-millestone'}>
								<Droppable
									droppableId={'no-id'}
									ref={div => (this._droppable = div)}
									hideList={this.props.collapsed}
									dragAndDropGroup={this.props.dragAndDropGroup}
									listHeaderHeight={!this.props.collapsed ? 44 : maxContainerHeight}
									activeHeaderClass={'drop-active'}
									containerHeight={maxContainerHeight}
									containerMinHeight={maxContainerHeight}
									elemHeight={taskHeight}
								>
									{this.props.collapsed
										? null
										: this.tasks.map((task, index) => (
												<Draggable
													disabled={
														!this.props.lazyDataFetched ||
														this.props.projectLocked ||
														task.readOnly?.isReadOnly
													}
													dragActiveClass={'active'}
													key={task.id}
													dragAndDropGroup={this.props.dragAndDropGroup}
													draggableId={task.id}
													noCancelOnMove={true}
												>
													<BacklogCard
														index={index}
														clearNewTask={this.clearNewTask.bind(this)}
														isNewTask={this.state.createdTaskId === task.companyTaskId}
														task={task}
														isConnectedParent={this.props.isConnectedParent}
														id={
															this.state.groupByPerson
																? task.id + '-' + this.props.personId
																: task.id
														}
														selectionMode={this.props.selectionMode}
														selected={this.getIsRowSelected(task.id)}
														key={'tr' + index}
														shouldHaveBorderLeft={true}
														onRowSelected={(rowId, shiftKey) =>
															this.props.handleRowSelected(
																rowId,
																shiftKey,
																this.tasks.map(t => {
																	return {node: t};
																})
															)
														}
														rowHeight={this.props.rowHeight ? this.props.rowHeight : 40}
														noBorderRight={true}
														onContextMenu={this.props.onContextMenu}
														viewer={this.props.viewer}
														roundingDecimals={1}
														disabled={this.props.projectLocked || task.readOnly?.isReadOnly}
														showTaskModal={this.props.showTaskModal}
													/>
												</Draggable>
										  ))}
								</Droppable>
							</div>
						)}

						{(this.props.draggingTask && riseAddTask) || noProject ? null : (
							<div className={'phase-bottom-section-wrapper'}>
								<div
									className={
										'phase-bottom-section' +
										(this.props.isConnectedParent ? ' connected' : '') +
										(this.state.newTaskInputValue ? ' buttonRight' : '')
									}
									style={riseAddTask ? addTaskStyle : null}
								>
									{this.props.isConnectedParent ? (
										<ProjectDropdown
											selectedProject={this.state.selectedProject}
											projectOptions={projectOptions}
											onChange={this.handleProjectSelect.bind(this)}
											customWidth={
												this.bottomAddTaskSection ? this.bottomAddTaskSection.clientWidth : null
											}
											contextHeight={this.props.contextHeight}
										/>
									) : null}
									<InputFieldV2
										customClass={'no-phase-add-control'}
										id={'newTask'}
										key={'add-task-input'}
										hideLabel={true}
										value={this.state.newTaskInputValue}
										locked={this.props.jiraStatusId || noProject || !this.props.lazyDataFetched}
										onChange={e => this.handleNewTaskInputChange(e)}
										placeholder={formatMessage({id: 'common.new-task'})}
										onEnter={this.handleAddTask.bind(this)}
										maxLength={300}
									/>
									{this.state.newTaskInputValue ? (
										<button onClick={this.handleAddTask.bind(this)} className={'new-task-button'} />
									) : null}
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>
		);
	}
}

SideBacklog.propTypes = {
	phase: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
	emptyPhase: PropTypes.bool.isRequired,
	locale: PropTypes.string,
	hasRateCard: PropTypes.bool.isRequired,
	focusAddControls: PropTypes.bool,
	projectLocked: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
	placeUnitBeforeValue: PropTypes.bool.isRequired,
	hasFinancialAccess: PropTypes.bool.isRequired,
	isJiraProject: PropTypes.bool.isRequired,
};

export default withRouter(injectIntl(SideBacklog, {forwardRef: true}));
