import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import TopSection from './sections/top_section';
import MainSection from './sections/main-section/main_section';
import OptionsSection from './sections/options_section';
import DescriptionSection from './sections/description_section';
import LabelSection from './sections/label_section';
import SubtaskSection from './sections/subtask-section/subtask_section';
import FilesSection from './sections/files_section';
import CommentSection from './sections/comment-section/comment_section';
import CommentFooterSection from './sections/comment_footer_section.js';
import FooterSection from './sections/footer-section/footer_section';
import {withRouter} from 'react-router-dom';
import Util from '../../forecast-app/shared/util/util';
import {DropTarget} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import {withSocketHandling} from '../../socket/withSocketHandling';
import NotFoundModal from '../../containers/modal/not_found_modal';
import TaskModalLoader from './task_modal_v3_loader';
import {injectIntl} from 'react-intl';
import {findDOMNode} from 'react-dom';
import {PROGRAM_BUDGET_TYPE, SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../constants';
import UpdateTaskMutation from '../../mutations/update_task_mutation.modern';
import {isSimulationMode} from '../canvas-scheduling/canvas-timeline/canvas_timeline_util';
import GithubSectionRenderer from './sections/github-section/github_section_renderer';
import * as tracking from '../../tracking';
import DependencySectionQueryContent, {
	DependencySectionQueryContentQuery,
} from './sections/dependency-section/DependencySectionQueryContent';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';
import TaskHierarchySection, {TaskHierarchySectionQuery} from './sections/task-hierarchy-section/TaskHierarchySection';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import BreadcrumbsSection from './sections/BreadcrumbsSection';
import DeleteTaskMutation from '../../mutations/delete_task_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/toast';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {cloneDeep} from 'lodash';
import {hasPermission, isClientUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import IntegrationSection from './sections/IntegrationSection';
import {PipedriveIcon} from 'web-components';
import ProjectUtil from '../../forecast-app/shared/util/project_util';
import CustomFieldsSection, {customFieldsSectionQuery} from './sections/custom_fields_section';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {trackTaskModalViewed} from '../../tracking/tracking_types/TrackTaskModalViewed';
import {
	canLoggedInPersonManageProgram,
	hasTopDownProgramBudgetFeature,
} from '../../forecast-app/shared/util/ProgramFinancialLogic';
import {ACTION} from '../../forecast-app/shared/components/modals/program-management/ProgramBudgetErrorMessage';
import {recentVisitedTaskKey} from '../../forecast-app/navigation/header/global-search/GlobalSearchModal';
import {isBillableSplitAllowed} from '../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';
import CustomScrollToNativeScroll from './../../forecast-app/shared/components/scroll-bars/CustomScrollToNativeScroll';
import {pathIncludesTask, removeTaskLinkFromUrl} from '../../forecast-app/shared/util/UrlUtil';

let actualTaskId;
// This will not always be up to date. But close enough
let hasCover;

const fileTarget = {
	drop(props, monitor) {
		const filesToUpload = monitor.getItem().files.map(file => {
			return {
				taskId: actualTaskId,
				mimeType: file.type,
				name: file.name,
				file,
			};
		});
		let coverFile = null;
		if (!hasCover) {
			coverFile = filesToUpload.find(f => {
				const ext = f.name.split('.').pop();
				return ext === 'png' || ext === 'jpg' || ext === 'gif';
			});
		}

		const onSuccess = result => {
			if (!hasCover && coverFile && result) {
				hasCover = true;
				Util.CommitMutation(UpdateTaskMutation, {
					ids: [actualTaskId],
					coverFileId: result.createFile.file.node.id,
				});
			}
		};
		Util.uploadFiles(filesToUpload, onSuccess, coverFile);
	},
};

class taskModalV3 extends Component {
	constructor(props) {
		super(props);

		const isClientViewRestricted = Util.isClientTaskViewRestricted(this.props.viewer);
		const isClientActionsRestricted = Util.isClientTaskActionsRestricted(this.props.viewer);

		this.state = {
			footerBottomPosition: 0,
			notFound: !this.props.viewer.task || !this.props.viewer.task.project || !this.props.viewer.task.fullAccessToProject,
			showLoader: true,
			height: 456,
			isDependencySectionExpanded: localStorage.getItem('task-modal-v3-dependencies-section-expanded') || false,
			dependenciesSectionAddedByUser: false,
			subtaskSectionAddedByUser: false,
			showComments: localStorage.getItem('task-modal-v3-comment-section-expanded') || false,
			showTimer:
				localStorage.getItem('task-modal-show-timer') && localStorage.getItem('task-modal-show-timer') === 'true',
			forceRerender: false,
			showFooterSection: !isClientViewRestricted && !isClientActionsRestricted,
			isClientViewRestricted,
			isClientActionsRestricted,
		};

		this.onResize = this.onResize.bind(this);
		this.onPaste = this.onPaste.bind(this);

		actualTaskId = props.viewer.task ? props.viewer.task.id : null;
		hasCover = props.viewer.task ? props.viewer.task.coverFile !== null : false;
	}

	componentDidMount() {
		this.onResize = this.onResize.bind(this);
		window.addEventListener('resize', this.onResize);
		window.addEventListener('paste', this.onPaste);
		const height = this.taskModalContent ? this.taskModalContent.getBoundingClientRect().height : 0;
		this.setState({height, showLoader: false});

		if (this.props.viewer.task && this.props.viewer.task.project) {
			const projectIds = parseInt(atob(this.props.viewer.task.project.id).replace('ProjectType:', ''));
			const taskId = parseInt(atob(this.props.viewer.task.id).replace('Task:', ''));
			const socketEvents = [
				{
					type: SOCKET_EVENT_TYPE.PROJECT,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.PROJECT,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.TASK,
					action: SOCKET_ACTION.UPDATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.TASK,
					action: SOCKET_ACTION.DELETE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.TIME_REG,
					action: SOCKET_ACTION.UPDATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.TIME_REG,
					action: SOCKET_ACTION.CREATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.TIME_REG,
					action: SOCKET_ACTION.DELETE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.SUB_TASK,
					action: SOCKET_ACTION.UPDATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.SUB_TASK,
					action: SOCKET_ACTION.CREATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.SUB_TASK,
					action: SOCKET_ACTION.DELETE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.COMMENT,
					action: SOCKET_ACTION.UPDATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.COMMENT,
					action: SOCKET_ACTION.CREATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.COMMENT,
					action: SOCKET_ACTION.DELETE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.FILE,
					action: SOCKET_ACTION.UPDATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.FILE,
					action: SOCKET_ACTION.CREATE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.FILE,
					action: SOCKET_ACTION.DELETE,
					taskIds: taskId,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.CREATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.SPRINT,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.PHASE,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.PHASE,
					action: SOCKET_ACTION.CREATE,
					projectIds: projectIds,
				},
				{
					type: SOCKET_EVENT_TYPE.PHASE,
					action: SOCKET_ACTION.UPDATE,
					projectIds: projectIds,
				},
			];
			if (!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
				socketEvents.push({
					type: SOCKET_EVENT_TYPE.PROJECT_PERSON,
					action: SOCKET_ACTION.DELETE,
					projectIds: projectIds,
					personIds: this.props.viewer.backendId,
				});
			}
			this.props.setSocketConfig(socketEvents);
		}

		if (this.props.viewer.task) {
			tracking.trackEvent('Task modal view', {_SubTask: !!this.props.viewer.task.parentTaskId});
			trackEvent('Task Modal', 'Opened');
			trackTaskModalViewed({
				companyId: this.props.viewer.company.id,
				personId: this.props.viewer.actualPersonId,
				taskId: this.props.viewer.task.id,
			});

			localStorage.setItem(recentVisitedTaskKey, this.props.viewer.task.id);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!this.props.viewer.task || !this.props.viewer.task.project || !this.props.viewer.task.fullAccessToProject) {
			this.setState({notFound: true});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('paste', this.onPaste);
	}

	onResize() {
		//Force render so that window.innerHeight that is used for scrollbar div max height is updated
		requestAnimationFrame(() => {
			this.forceUpdate();
		});
	}

	onPaste(e) {
		if (!e.clipboardData.items || !e.clipboardData.items.length) return;
		const filesToUpload = [];
		for (let i = 0; i < e.clipboardData.items.length; i++) {
			const file = e.clipboardData.items[i].getAsFile();
			if (!file) continue;
			filesToUpload.push({
				taskId: this.props.viewer.task.id,
				mimeType: file.type,
				name: file.name,
				file,
			});
		}
		if (!filesToUpload.length) return;

		let coverFile = null;
		if (!this.props.viewer.task.coverFile) {
			coverFile = filesToUpload.find(f => {
				const ext = f.name.split('.').pop();
				return ext === 'png' || ext === 'jpg' || ext === 'gif';
			});
		}

		const onSuccess = result => {
			if (!this.props.viewer.task.coverFile && coverFile && result) {
				hasCover = true;
				Util.CommitMutation(UpdateTaskMutation, {
					ids: [this.props.viewer.task.id],
					coverFileId: result.createFile.file.node.id,
				});
			}
		};
		Util.uploadFiles(filesToUpload, onSuccess, coverFile);
	}

	closeModal() {
		//Trigger blur so that if any of the inputs is focused, we send the mutation
		if (document.activeElement) {
			document.activeElement.blur();
		}
		const path = window.location.pathname;
		if (pathIncludesTask(path)) {
			// Task modal is open. Redirect.
			let newUrl = removeTaskLinkFromUrl(path);
			newUrl = Util.appendURLTag(newUrl);
			this.props.history.push(newUrl, {taskModalClosed: true});
		}
	}

	scrollToBottom() {
		if (this.customScrollDiv) this.customScrollDiv.animateScrollTop(this.customScrollDiv.scrollbars.getScrollHeight());
	}

	scrollToRef(ref, position) {
		if (findDOMNode(ref)) {
			// check if the subtask input is not visible and scrolls to it. If it is visible nothing happens
			const {bottom, top} = findDOMNode(ref).getBoundingClientRect();
			if (position !== 'top') {
				if (bottom < 260 || bottom > window.innerHeight - 100) {
					const newScrollTopValue =
						bottom +
						this.customScrollDiv.scrollbars.getScrollTop() -
						216 -
						this.customScrollDiv.scrollbars.getClientHeight();
					requestAnimationFrame(() => {
						this.customScrollDiv.scrollbars.scrollTop(newScrollTopValue);
					});
				}
			} else {
				const newScrollTopValue = top + this.customScrollDiv.scrollbars.getScrollTop() - 216;
				requestAnimationFrame(() => {
					this.customScrollDiv.scrollbars.scrollTop(newScrollTopValue);
				});
			}
		}
	}

	toggleActivities() {
		const footerBottomPosition = this.taskModalInnerContent.getBoundingClientRect().height + 47; // 47 from absolute positioned comment input
		this.setState({footerBottomPosition});
	}

	onInputExpanded(val) {
		this.setState({inputExpanded: val});
	}

	addDependenciesSection() {
		if (!this.state.dependenciesSectionAddedByUser) {
			tracking.trackSectionAdded({section: 'dependecies'});
			trackEvent('Dependencies', 'Section Added');
		}
		this.setState({dependenciesSectionAddedByUser: !this.state.dependenciesSectionAddedByUser}, () =>
			this.scrollToRef(this.dependencySection, 'top')
		);
		this.toggleDependencySection(true);
	}

	addSubtaskSection() {
		const isTwoLevelSubTask = this.props.viewer.task.project.taskLevels === 2;
		if (!this.state.subtaskSectionAddedByUser) {
			tracking.trackSectionAdded({section: isTwoLevelSubTask ? 'subtask' : 'todo'});
			trackEvent('Subtask', 'Section Added');
		}
		this.setState({subtaskSectionAddedByUser: !this.state.subtaskSectionAddedByUser}, () =>
			this.scrollToRef(this.subtaskSection, 'top')
		);

		// Because animate.css uses transform to animate, it breaks the drag and drop functionality
		// Therefore we rerender without the animation classes on the container
		if (!this.state.forceRerender) {
			setTimeout(() => {
				this.setState({forceRerender: true});
			}, 900);
		}
	}

	onStatusColumnChange(task, option) {
		const {intl} = this.props;
		Util.changeTaskStatus(task, option, intl, this.props.viewer.company.id);
	}

	deleteTask(task) {
		const affectedStatusColumn = task.statusColumnV2;
		const filters = [affectedStatusColumn.id];
		const project = this.props.viewer.task.project;

		const parentTaskId = task.parentTaskId;
		const deletedFromParent = parentTaskId === this.props.viewer.task.id;
		const parentCompanyTaskId = deletedFromParent ? this.props.viewer.task.companyTaskId : task.parentTask?.companyTaskId;

		const onSuccess = () => {
			createToast({
				duration: 4000,
				message: this.props.intl.formatMessage({
					id: parentCompanyTaskId ? 'task_modal.subtask-has-been-deleted' : 'task_modal.has-been-deleted',
				}),
				actionText: null,
				actionCallback: null,
			});
		};

		if (affectedStatusColumn.projectGroupStatusColumnId) {
			filters.push(affectedStatusColumn.projectGroupStatusColumnId);
		}
		Util.CommitSchedulingModalUpdate(
			DeleteTaskMutation,
			{
				ids: [task.id],
				projectId: project.id,
				filters: filters,
				viewer: this.props.viewer,
				parentTaskId: parentTaskId,
			},
			onSuccess
		);

		if (parentCompanyTaskId) {
			if (deletedFromParent) return;
			Util.showTaskModal(parentCompanyTaskId, this.props.history);
		} else {
			this.closeModal();
		}
	}

	stopTimer(task, preventedDelete) {
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
				timerActionTaskId: task.id,
				preventedDelete: preventedDelete,
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder: false,
				timerProject: task.project,
				timerTask: task,
				preventedDelete: preventedDelete,
				personId: this.props.viewer.actualPersonId,
			});
		}
	}

	showDeletePrompt(task) {
		if (this.props.viewer.timerStartDate) {
			if (this.props.viewer.timerTask && task.id === this.props.viewer.timerTask.id) {
				this.stopTimer(task, true);
				return;
			}
		}

		showModal({
			type: MODAL_TYPE.TASK_DELETION_WARNING,
			task,
			deleteCallback: () => this.deleteTask(task),
		});
	}

	toggleDependencySection(shouldExpand) {
		if (shouldExpand === true) {
			this.setState({isDependencySectionExpanded: true}, () =>
				Util.localStorageSetItem('task-modal-v3-dependencies-section-expanded', this.state.isDependencySectionExpanded)
			);
		} else {
			this.setState({isDependencySectionExpanded: !this.state.isDependencySectionExpanded}, () =>
				this.state.isDependencySectionExpanded
					? Util.localStorageSetItem(
							'task-modal-v3-dependencies-section-expanded',
							this.state.isDependencySectionExpanded
					  )
					: localStorage.removeItem('task-modal-v3-dependencies-section-expanded')
			);
		}
	}

	toggleComments(shouldExpand) {
		if (shouldExpand === true) {
			this.setState({showComments: true}, () =>
				Util.localStorageSetItem('task-modal-v3-comment-section-expanded', this.state.showComments)
			);
		} else {
			this.setState({showComments: !this.state.showComments}, () => {
				this.state.showComments
					? Util.localStorageSetItem('task-modal-v3-comment-section-expanded', this.state.showComments)
					: localStorage.removeItem('task-modal-v3-comment-section-expanded');
			});
		}
	}

	showProgramRevenueLockedMessage = () => {
		const program = this.props.viewer.task.project.program;
		const loggedInPersonId = this.props.viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.INITIATE_TIMER,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});

		trackEvent('Program Budget Error Message Modal', 'Shown');
	};

	toggleTimer() {
		if (
			hasTopDownProgramBudgetFeature() &&
			!isBillableSplitAllowed() &&
			this.props.viewer.task.project.isProgramRevenueLocked &&
			this.props.viewer.task.project.programBudgetType === PROGRAM_BUDGET_TYPE.CAPPED &&
			this.props.viewer.task.billable &&
			!this.state.showTimer
		) {
			this.showProgramRevenueLockedMessage();
			return;
		}

		if (!this.state.showTimer) {
			tracking.trackSectionAdded({section: 'timer'});
			trackEvent('Timer', 'Section Added');
		}
		Util.localStorageSetItem('task-modal-show-timer', !this.state.showTimer);
		this.setState({showTimer: !this.state.showTimer});
	}

	/**
	 * Determine if the task hierarchy sec should be shown
	 * @param hasTaskHierarchyFeatureFlag
	 * @param isJiraProject
	 * @param currentTaskDepth 0: parent, 1: subtask, 2: subsubtask
	 * @returns {boolean}
	 */
	showTaskHierarchySection(hasTaskHierarchyFeatureFlag, currentTaskDepth) {
		if (hasTaskHierarchyFeatureFlag) {
			return currentTaskDepth < 2;
		} else {
			return false;
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer} = this.props;
		const {showFooterSection, isClientActionsRestricted} = this.state;

		if (this.state.notFound) {
			return (
				<NotFoundModal
					title={formatMessage({id: 'card_not_found_modal.title'})}
					message={formatMessage({id: 'card_not_found_modal.info'})}
					closeModal={this.closeModal.bind(this)}
				/>
			);
		}
		const {task} = viewer;
		if (!task || !task.id || !task.fullAccessToProject) {
			return (
				<NotFoundModal
					title={formatMessage({id: 'card_not_found_modal.title'})}
					message={formatMessage({id: 'card_not_found_modal.info'})}
					closeModal={this.closeModal.bind(this)}
				/>
			);
		}

		const {project} = task;
		const isTaskReadOnly = task.readOnly?.isReadOnly || isSimulationMode();
		const isViewerClientUser = isClientUser();
		const {connectDropTarget, isOver} = this.props;
		const taskHierachyFlag = viewer.task.project.useTaskHierarchy;
		const parentsList = [];
		if (taskHierachyFlag) {
			if (hasFeatureFlag('forecast_epics')) {
				let parentTask = task.parentTask;
				while (parentTask && parentTask.taskType !== 'EPIC') {
					parentsList.unshift(parentTask);
					parentTask = parentTask.parentTask;
				}
			} else {
				if (task.parentTask) {
					parentsList.push(task.parentTask);
					if (task.parentTask.parentTask) {
						parentsList.unshift(task.parentTask.parentTask);
					}
				}
			}
		}

		const statusOptions = cloneDeep(project.statusColumnsV2?.edges || [])
			.filter(statusColumn => {
				return (!task.jiraKey || !!statusColumn.node.jiraStatusId) && (!task.vstsId || !!statusColumn.node.adoState);
			})
			.sort((a, b) => {
				const comparisonResult = a.node.order - b.node.order;
				if (comparisonResult > 0) {
					return 1;
				} else if (comparisonResult < 0) {
					return -1;
				} else {
					return 0;
				}
			})
			.map(statusColumn => {
				return {
					label: statusColumn.node.name,
					value: statusColumn.node.id,
					category: statusColumn.node.category,
					projectGroupStatusColumnId: statusColumn.node.projectGroupStatusColumnId,
					clearOption: true,
					clickable: true,
					nestedOptions: [],
					logo: statusColumn.node.jiraStatusId ? 'jira-logo' : statusColumn.node.adoState ? 'ado-logo' : undefined,
				};
			});

		let availableRoles = viewer.company.roles?.edges || [];
		if (project.rateCard && project.rateCard.disabledRoles) {
			const disabledRoles = project.rateCard.disabledRoles.map(role => role.id);
			availableRoles = availableRoles.filter(edge => !disabledRoles.includes(edge.node.id));
		}

		const taskModalContent = (
			<div
				className={
					'task-task-modal-v3-content' +
					(isTaskReadOnly ? ' project-locked' : '') +
					(showFooterSection ? '' : ' footer-section-hidden') +
					// For some reason, the footer is absolutely positioned despite following the DOM-hierarchy. This messes with native scrolling because the footer can grow and shrink on focus. This CSS-class instead converts the footer to statically positioned elements.
					// When the feature flag is rolled out fully, the CSS of the footer elements should just be changed entirely.
					(hasFeatureFlag('native_scrollbar_replacement') ? ' no-absolute-footer' : '')
				}
				onMouseDown={e => e.stopPropagation()}
				ref={e => (this.taskModalContent = e)}
				data-cy="task-modal-content"
				data-userpilot={'task-modal'}
			>
				{isOver ? (
					<div className="drop-file-overlay">
						<div className="dropdown-icon">
							<div className="dropdown-arrow">
								<svg xmlns="http://www.w3.org/2000/svg" width="23" height="26" viewBox="0 0 23 26">
									<path
										fill="#6E0FEA"
										fillRule="evenodd"
										d="M11.5 0L0 11.062l2.85 2.74L9.484 7.42V26h4.03V7.42l6.636 6.382L23 11.062z"
									/>
								</svg>
							</div>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="8" viewBox="0 0 32 8">
								<path
									fill="#6E0FEA"
									fillRule="evenodd"
									d="M28 0v4H4V0H0v4c0 2.206 1.794 4 4 4h24c2.206 0 4-1.794 4-4V0h-4z"
								/>
							</svg>
						</div>
						<div className="dropdown-text">{formatMessage({id: 'task_modal.drop_file'})}</div>
					</div>
				) : null}

				{taskHierachyFlag ? (
					<BreadcrumbsSection
						parentsList={parentsList}
						taskName={task.name}
						history={this.props.history}
						closeModal={this.closeModal.bind(this)}
					/>
				) : (
					<div className="cut-off-corner-box-container">
						<svg width="920" height="15">
							<polygon points="0,0 899,0 920,13, 920,15 0,15" style={{fill: 'white'}} />
						</svg>
					</div>
				)}
				<TopSection
					viewer={viewer}
					task={task}
					project={project}
					isTaskReadOnly={isTaskReadOnly}
					closeModal={this.closeModal.bind(this)}
					addDependenciesSection={this.addDependenciesSection.bind(this)}
					dependenciesSectionAddedByUser={this.state.dependenciesSectionAddedByUser}
					addSubtaskSection={this.addSubtaskSection.bind(this)}
					showDeletePrompt={this.showDeletePrompt.bind(this)}
					statusOptions={statusOptions}
					onStatusColumnChange={this.onStatusColumnChange.bind(this)}
					subtaskSectionAddedByUser={this.state.subtaskSectionAddedByUser}
					toggleTimer={this.toggleTimer.bind(this)}
					showTimer={this.state.showTimer}
					taskHierachyFlag={taskHierachyFlag}
					connectedProject={this.props.connectedProject}
					hasParent={parentsList.length > 0}
					parentTaskId={task.parentTask?.companyTaskId}
					hasChildren={task.hasChildren}
				/>
				<MainSection
					showTimeEntries={!this.state.showLoader}
					viewer={viewer}
					availableRoles={availableRoles}
					task={task}
					project={project}
					isClientUser={isViewerClientUser}
					isTaskReadOnly={isTaskReadOnly}
					closeModal={this.closeModal.bind(this)}
					hasChildren={task.hasChildren}
				/>
				<div ref={e => (this.taskModalInnerContent = e)}>
					<CustomScrollToNativeScroll
						maxHeight={window.innerHeight - 320 - (this.state.inputExpanded ? this.state.inputExpanded : 0)}
						hasFocusableContent
						// Old scroller props
						className={'custom-scrollbar-div'}
						hideTracksWhenNotNeeded={true}
						autoHeight={true}
						marginBottom={this.state.inputExpanded}
						autoHeightMax={window.innerHeight - 320 - (this.state.inputExpanded ? this.state.inputExpanded : 0)}
						renderTrackHorizontal={props => <div {...props} className="track track-horizontal" />}
						renderTrackVertical={props => <div {...props} className="track track-vertical" />}
						renderThumbHorizontal={props => <div {...props} className="thumb thumb-horizontal" />}
						renderThumbVertical={props => <div {...props} className="thumb thumb-vertical" />}
						ref={e => (this.customScrollDiv = e)}
					>
						<OptionsSection
							task={task}
							viewer={viewer}
							company={viewer.company}
							project={project}
							showTimer={this.state.showTimer}
							disabled={isTaskReadOnly || isClientActionsRestricted}
						/>
						<DescriptionSection
							viewer={viewer}
							task={task}
							project={project}
							isTaskReadOnly={isTaskReadOnly}
							isClientUser={isViewerClientUser}
							toggleDependencySection={this.toggleDependencySection.bind(this)}
							isDependencySectionExpanded={this.state.isDependencySectionExpanded}
							addDependenciesSection={this.addDependenciesSection.bind(this)}
						/>
						{task.pipedriveId && (
							<IntegrationSection
								link={`${viewer.company.pipedriveCompanyDomain}/activities/?selected=${task.pipedriveId}&tab=activity`}
								systemName={'Pipedrive'}
								integrationIcon={<PipedriveIcon size={PipedriveIcon.SIZE.TASK_MODAL} />}
							/>
						)}

						<FilesSection task={task} viewer={viewer} isTaskReadOnly={isTaskReadOnly} />

						<LabelSection viewer={viewer} task={task} disabled={isTaskReadOnly || isClientActionsRestricted} />

						{!isViewerClientUser &&
						!project.isJiraProject &&
						(this.state.dependenciesSectionAddedByUser ||
							task.thisTaskDependsOn?.edges?.length !== 0 ||
							task.dependsOnThisTask?.edges?.length !== 0) ? (
							<ForecastQueryRenderer
								key="query-render-dependency-section"
								query={DependencySectionQueryContentQuery}
								customLoader={() => <InlineLoader />}
								variables={{id: viewer.task.companyTaskId.toString()}}
								render={(relayProps, retry) => {
									return (
										<DependencySectionQueryContent
											{...relayProps}
											retry={retry}
											task={task}
											project={project}
											ref={e => (this.dependencySection = e)}
											isTaskReadOnly={isTaskReadOnly}
											isClientUser={isViewerClientUser}
											projectColor={project.projectColor}
											expanded={this.state.isDependencySectionExpanded}
											addedByUser={this.state.dependenciesSectionAddedByUser}
											toggleDependencySection={this.toggleDependencySection.bind(this)}
										/>
									);
								}}
							/>
						) : null}

						{task.subTasks?.edges?.length || this.state.subtaskSectionAddedByUser ? (
							<SubtaskSection
								ref={e => (this.subtaskSection = e)}
								viewer={viewer}
								task={task}
								project={project}
								disabled={isTaskReadOnly || isClientActionsRestricted}
								scrollToSubtaskInput={this.scrollToRef.bind(this, this.subtaskSection)}
								addedByUser={this.state.subtaskSectionAddedByUser}
								forceRerender={this.state.forceRerender}
							/>
						) : null}

						{this.showTaskHierarchySection(taskHierachyFlag, parentsList.length) && (
							<ForecastQueryRenderer
								key="query-render-task-hierarchy-section"
								query={TaskHierarchySectionQuery}
								customLoader={() => <InlineLoader />}
								variables={{id: viewer.task.companyTaskId.toString()}}
								render={(relayProps, retry) => {
									return (
										<TaskHierarchySection
											{...relayProps}
											retry={retry}
											parentId={task.id}
											projectId={project.id}
											companyId={viewer.company.id}
											companyRoles={availableRoles}
											projectPersons={viewer.task.project.projectPersons?.edges}
											disabled={isTaskReadOnly}
											hideTimeRegistrations={ProjectUtil.projectUsesManualProgress(project)}
											showSuggestions={hasFeatureFlag(
												'suggestions_new_task',
												this.props.viewer.availableFeatureFlags
											)}
											statusOptions={statusOptions}
											onStatusColumnChange={this.onStatusColumnChange.bind(this)}
											showDeletePrompt={this.showDeletePrompt.bind(this)}
											isEstimatedInHours={viewer.task.project.estimationUnit === 'HOURS'}
										/>
									);
								}}
							/>
						)}

						{!this.state.showLoader && viewer.company.githubEnabled && !isViewerClientUser ? (
							<GithubSectionRenderer
								viewer={viewer}
								task={task}
								project={project}
								isTaskReadOnly={isTaskReadOnly}
								isClientUser={isViewerClientUser}
							/>
						) : null}
						<CommentSection
							ref={e => (this.commentSectionRef = e)}
							viewer={viewer}
							task={task}
							project={project}
							isTaskReadOnly={isTaskReadOnly}
							toggleComments={this.toggleComments.bind(this)}
							showComments={this.state.showComments}
						/>
						{Util.hasCustomFields() && (
							<ForecastQueryRenderer
								key="query-render-task-custom-fields"
								query={customFieldsSectionQuery}
								showLoader={false}
								variables={{
									taskId: task.id,
								}}
								render={relayProps => <CustomFieldsSection {...relayProps} />}
							/>
						)}
					</CustomScrollToNativeScroll>
				</div>

				<CommentFooterSection
					viewer={viewer}
					project={project}
					isTaskReadOnly={isTaskReadOnly}
					showFooterSection={showFooterSection}
					task={task}
					scrollToBottom={this.scrollToBottom.bind(this)}
					onInputExpanded={this.onInputExpanded.bind(this)}
					commentSectionRef={this.commentSectionRef}
					toggleComments={this.toggleComments.bind(this)}
				/>

				{showFooterSection && (
					<FooterSection
						viewer={viewer}
						task={task}
						project={project}
						isTaskReadOnly={isTaskReadOnly}
						toggleActivities={this.toggleActivities.bind(this)}
						footerBottomPosition={this.state.footerBottomPosition}
						closeModal={this.closeModal.bind(this)}
						isClientUser={isViewerClientUser}
					/>
				)}
			</div>
		);

		const style = {
			transition: 'all 0.4s',
			height: this.state.height - 89 - 14, // subtract padding and cut-off-corner-box height
			opacity: 0,
		};
		if (this.state.showLoader) {
			return (
				<TaskModalLoader>
					<div style={style}>{taskModalContent}</div>
				</TaskModalLoader>
			);
		}
		return (
			<div data-cy="real-task-modal" className="task-modal-v3" onMouseDown={this.closeModal.bind(this)}>
				{isTaskReadOnly ? taskModalContent : connectDropTarget(taskModalContent)}
			</div>
		);
	}
}

const taskModalV3Query = graphql`
	query taskModalV3_Query($id: String) {
		viewer {
			actualPersonId
			component(name: "task_modal_v3")
			...taskModalV3_viewer @arguments(id: $id)
		}
	}
`;

export {taskModalV3Query};

const task = graphql`
	fragment taskModalV3_task on Task {
		id
		userCanDeleteTask
		userCantDeleteTaskReason
		fullAccessToProject
		approved
		name
		readOnly {
			isReadOnly
		}
		coverFile {
			id
		}
		parentTaskId
		parentTask {
			id
			companyTaskId
			name
			taskType
			parentTask {
				id
				companyTaskId
				name
				taskType
			}
		}
		hasChildren
		companyTaskId
		blocked
		bug
		billable
		highPriority
		startYear
		startMonth
		startDay
		description
		jiraId
		jiraKey
		gitlabIid
		vstsId
		pipedriveId
		isUnit4Task
		estimateForecast
		timeLeft
		deadlineDay
		deadlineMonth
		deadlineYear
		startFrom
		deadlineFrom
		gitlabIid
		gitlabServerIid
		hasLockedTime
		hasInvoicedTime
		favoured
		progressDetails {
			progress
		}
		followers {
			id
			firstName
			lastName
			profilePictureId
			profilePictureDefaultId
		}
		taskLabels {
			label {
				id
				name
				color
			}
		}
		subTasks(first: 1000) @connection(key: "Task_subTasks") {
			edges {
				node {
					id
					estimate
					done
					sortOrder
					name
					description
					startDay
					startMonth
					startYear
					endDay
					endMonth
					endYear
					startFrom
					endFrom
					role {
						id
						name
					}
					person {
						id
						firstName
						lastName
						active
						profilePictureId
						profilePictureDefaultId
					}
				}
			}
		}
		thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
			edges {
				node {
					id
					type
					completed
					taskDependsOnThis {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
					thisDependsOnTask {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
				}
			}
		}
		dependsOnThisTask(first: 1000) @connection(key: "Task_dependsOnThisTask") {
			edges {
				node {
					id
					type
					completed
					taskDependsOnThis {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
					thisDependsOnTask {
						id
						name
						companyTaskId
						startYear
						startMonth
						startDay
						deadlineDay
						deadlineMonth
						deadlineYear
						statusColumnV2 {
							id
							category
						}
					}
				}
			}
		}
		repeatingTask {
			id
			repeatType
			monday
			tuesday
			wednesday
			thursday
			friday
			saturday
			sunday
			monthlyDay
		}
		statusColumnV2 {
			id
			name
			category
			projectGroupStatusColumnId
		}
		phase {
			id
			name
			startYear
			startMonth
			startDay
			deadlineDay
			deadlineMonth
			deadlineYear
		}
		sprint {
			id
			name
			startYear
			startMonth
			startDay
			endDay
			endMonth
			endYear
		}
		assignedPersons {
			id
			firstName
			lastName
			role {
				id
				name
			}
			profilePictureId
			profilePictureDefaultId
			email
		}
		timeRegistrations(first: 5000) @connection(key: "Task_timeRegistrations") {
			edges {
				node {
					id
					invoiced
					xeroInvoiceId
					minutesRegistered
					lockedInPeriod
				}
			}
		}
		role {
			id
			name
		}
		coverFile {
			id
		}
		googleDriveFiles(first: 10000) @connection(key: "Task_googleDriveFiles") {
			edges {
				node {
					id
					name
					link
					thumb
				}
			}
		}
		files(first: 10000) @connection(key: "Task_files") {
			edges {
				node {
					id
					name
					mimeType
					key
					size
					yearCreated
					monthCreated
					dayCreated
					hourCreated
					minuteCreated
					secondCreated
					person {
						id
						fullName
					}
				}
			}
		}
		commentCount
		comments(first: 100000) @connection(key: "Task_comments") {
			edges {
				node {
					id
					comment
					year
					month
					day
					hours
					minutes
					seconds
					person {
						id
						firstName
						lastName
						fullName
						profilePictureId
						profilePictureDefaultId
					}
				}
			}
		}
		createdAt
		createdBy {
			id
			firstName
			lastName
		}
		latestUiUpdateAt
		latestUiUpdateBy {
			id
			firstName
			lastName
		}
		owner {
			id
			firstName
			lastName
			fullName
			profilePictureId
			profilePictureDefaultId
			role {
				id
				name
			}
		}
		project {
			id
			name
			useTaskOwner
			useTaskFollowers
			useTaskHierarchy
			projectStartYear
			projectStartMonth
			projectStartDay
			projectEndYear
			projectEndMonth
			projectEndDay
			status
			projectColor
			isJiraProject
			jiraSubtaskType
			jiraCloudProject {
				id
			}
			jiraCloudEpicIds
			jiraServerProject {
				id
			}
			gitlabProjectName
			gitlabServerProjectName
			projectGroupId
			billable
			taskLevels
			companyProjectId
			customProjectId
			...DeprecatedProjectIndicatorJS_project
			billable
			isProgramRevenueLocked
			programBudgetType
			program {
				name
				prefix
				budgetType
				members {
					edges {
						node {
							role
							person {
								id
							}
						}
					}
				}
			}
			harvestProject {
				id
			}
			manualProgressOnProjectEnabled
			manualProgressOnPhasesEnabled
			manualProgressOnTasksEnabled
			vstsProject
			vstsAccount
			vstsTwoWaySync
			sprintTimeBox
			estimationUnit
			remainingAutoCalculated
			rateCard {
				id
				disabledRoles {
					id
				}
			}
			projectPersons(first: 10000) @connection(key: "Project_projectPersons", filters: []) {
				edges {
					node {
						id
						person {
							id
							firstName
							lastName
							fullName
							profilePictureId
							profilePictureDefaultId
							active
							permissions
							harvestUser
						}
						project {
							id
						}
						role {
							id
							name
						}
					}
				}
			}
			client {
				id
				name
				logoId
				logoDefaultId
			}
			phases(first: 10000) {
				edges {
					node {
						id
						name
						startYear
						startMonth
						startDay
						deadlineYear
						deadlineMonth
						deadlineDay
					}
				}
			}
			statusColumnsV2(first: 1000000) {
				edges {
					node {
						id
						name
						category
						order
						jiraStatusId
						adoState
						projectGroupStatusColumnId
						userActions {
							canDelete
							canDeleteWithTasks
							canCreateTask
							canRename
						}
					}
				}
			}
			sprints(first: 10000) {
				edges {
					node {
						id
						name
						startYear
						startMonth
						startDay
						endYear
						endMonth
						endDay
					}
				}
			}
			program {
				name
				prefix
				members {
					edges {
						node {
							role
							person {
								id
							}
						}
					}
				}
			}
		}
	}
`;

export {task};

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
}))(
	withSocketHandling(
		injectIntl(
			withRouter(
				createFragmentContainer(taskModalV3, {
					viewer: graphql`
						fragment taskModalV3_viewer on Viewer @argumentDefinitions(id: {type: "String"}) {
							id
							backendId
							actualPersonId
							email
							firstName
							lastName
							harvestUser
							timerStartDate
							timerEndDate
							useDetailedSubtasks
							githubConnected
							profilePictureId
							profilePictureDefaultId
							availableFeatureFlags {
								key
							}
							company {
								...optionsSection_company
								id
								modules {
									moduleType
								}
								pipedriveCompanyDomain
								githubEnabled
								gdriveEnabled
								gitlabServerUrl
								characterLimit
								roles(first: 10000) {
									edges {
										...RoleDropdown_roles
										node {
											id
											name
										}
									}
								}
								labels(first: 10000) {
									edges {
										node {
											id
											name
											category {
												allowOnTasks
												allowOnProjects
												allowOnPeople
											}
											...LabelDropdown_labels
										}
									}
								}
							}
							timerTask {
								id
							}
							client {
								id
								name
							}

							task(companyTaskId: $id) {
								...taskModalV3_task @relay(mask: false)
							}
						}
					`,
				})
			)
		)
	)
);
